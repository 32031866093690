import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./features/Login/LoginView";
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from "react-oidc-context";
import QuizIndexView from './features/Quiz/Views/QuizIndex';
import 'bootstrap/dist/css/bootstrap.min.css';
import AddQuizView from './features/Quiz/Views/AddQuizView';
import EditQuizView from './features/Quiz/Views/EditQuizView';
import UserRegisteredView from './features/Login/UserRegisteredView';
import { ProtectedRoute } from './routes/ProtectedRoutes';
import ConfirmationInvitationView from './features/Mail/Views/ConfirmationView';
import { ConfirmationType } from './features/Mail/Enum/ConfirmationType';
import { WebStorageStateStore } from 'oidc-client-ts';
import UserInfoView from './features/User/Views/UserInfoView';


export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<ProtectedRoute />}>
          <Route path="/quiz/add" element={<AddQuizView />} />
          <Route path="/quiz/edit/:id" element={<EditQuizView />} />
          <Route path="/userManagement" element={<QuizIndexView />} />
          <Route path="/userInfo" element={<UserInfoView/>}/>
        </Route>
        <Route path="/quiz" element={<QuizIndexView />} />
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<Login />} />
        <Route path="/account/registered" element={<UserRegisteredView />} />

        <Route path="/account/registered/:key/:userId" element={<ConfirmationInvitationView type={ConfirmationType.RegisterToSystem} />} />
        <Route path="/invitationtoquiz/:key" element={<ConfirmationInvitationView type={ConfirmationType.InvitationToQuiz} />} />
      </Routes>
    </BrowserRouter>
  );
}

function onSigninCallback() {
  window.location.href = "/quiz";
}


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const oidcConfig = {
  authority: process.env.REACT_APP_IdentityAuthority,
  client_id: process.env.REACT_APP_IdentityClientId,
  client_secret: process.env.REACT_APP_IdentityClientSecret,
  redirect_uri: process.env.REACT_APP_IdentityRedirectUrl,
  scope: process.env.REACT_APP_IdentityScopes,
  loadUserInfo: true,
  automaticSilentRenew: true,
  userStore: new WebStorageStateStore({
    store: localStorage
  })
};

if (window.location.hash !== '') {
} else {
  root.render(
    <React.StrictMode>
      <AuthProvider {...oidcConfig} onSigninCallback={onSigninCallback}>
        <App />
      </AuthProvider>
    </React.StrictMode>
  );
}


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
