import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  Checkbox,
  FormControlLabel,
  IconButton,
} from "@mui/material";
import { RandomizeTeamsDialogProps } from "../Props/RandomizeTeamDialog";
import { randomizeQuizTeams } from "../Shared/QuizTeamRandomizerHelper";
import RefreshIcon from '@mui/icons-material/Refresh';
import { QuizTeamWithQuizPlayers } from "../../../interfaces/QuizTeam/QuizTeamWithQuizPlayers";

const RandomizeTeamsDialog: React.FC<RandomizeTeamsDialogProps> = ({
  open,
  onClose,
  onRandomize,
  quizPlayers
}) => {
  const quizPlayersToRandomize = [...quizPlayers]
  const maxAmountOfTeams = quizPlayers.length;
  const [amountOfTeams, setAmountOfTeams] = useState<number>(quizPlayers.length);
  const [makeATeamForEveryPlayer, setMakeATeamForEveryPlayer] = useState<boolean>(false);
  const [randomizedQuizTeams, setRandomizedQuizTeams] = useState<QuizTeamWithQuizPlayers[]>([]);

  useEffect(() => {
    setAmountOfTeams(maxAmountOfTeams);
    setRandomizedQuizTeams(randomizeQuizTeams(quizPlayersToRandomize, maxAmountOfTeams, false));
  }, [makeATeamForEveryPlayer, quizPlayers]);

  const handleAmountQuizTeamChanged = (e) => {
    const changedTeamAmount = Number(e.target.value);
    let newAmountTeam: number;

    if (changedTeamAmount >= 1 && changedTeamAmount <= maxAmountOfTeams) {
        newAmountTeam = changedTeamAmount;
      } else if (changedTeamAmount < 1) {
        newAmountTeam = 1;
      } else {
        newAmountTeam = maxAmountOfTeams;
      }

      setAmountOfTeams(newAmountTeam);
      setRandomizedQuizTeams(randomizeQuizTeams(quizPlayersToRandomize, amountOfTeams, makeATeamForEveryPlayer));
  }

  const handleRandomize = () => {
    onRandomize(randomizedQuizTeams);
    onClose();
  };

  const reRandomizeQuizTeams = () => {
    setRandomizedQuizTeams(randomizeQuizTeams(quizPlayersToRandomize, amountOfTeams, makeATeamForEveryPlayer));
  }

  return (
    <Dialog className="randomize-quizteam-dialog" open={open} onClose={onClose} fullWidth>
      <DialogTitle>Randomize Teams</DialogTitle>
      <DialogContent>
        <FormControlLabel
          control={
            <Checkbox
              checked={makeATeamForEveryPlayer}
              onChange={(e) => setMakeATeamForEveryPlayer(e.target.checked)}
              color="primary"
            />
          }
          label="Maak van elke speler een team"
        />

        <TextField
            label="Aantal teams"
            type="number"
            value={amountOfTeams}
            fullWidth
            onChange={handleAmountQuizTeamChanged}
            onBlur={handleAmountQuizTeamChanged}
            inputProps={{ min: 1, max: maxAmountOfTeams }}
            disabled={makeATeamForEveryPlayer}
            onFocus={(e) => e.target.select()}/>
        
        <div>
          <div className="teamdistribution-title-row">
            <h4>Teamverdeling:</h4>
            <IconButton color="primary" onClick={reRandomizeQuizTeams}>
              <RefreshIcon />
            </IconButton>
          </div>
          <div className="randomized-quizteams-container">
              {quizPlayersToRandomize.length == 0 ? <div>Geen niet toegewezen spelers.</div> : randomizedQuizTeams.map((team, index) => <>
                <div className="randomized-quizteam-item">
                    <div className="randomized-quizteam-title">team {index + 1} - ({team.quizPlayers.length})</div>
                     <ul>
                      {team.quizPlayers.map(quizPlayer => 
                        <>
                          <li>{quizPlayer.quizPlayerName}</li>
                        </>
                      )}
                     </ul>
                </div>
              </>)}
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleRandomize} color="primary" variant="contained">
          Randomize
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RandomizeTeamsDialog;
