import { useAuth } from "react-oidc-context";
import { Outlet, useNavigate } from "react-router-dom";
import { useEffect } from "react";

export const ProtectedRoute = () => {
    const navigate = useNavigate();
    const { isAuthenticated, isLoading } = useAuth();

    useEffect(() => {
        if (!isLoading && !isAuthenticated) {
            navigate("/login");
        }
    }, [isAuthenticated, isLoading, navigate]); 

    if (isLoading) {
        return <div>Loading...</div>;
    }

  if (!isAuthenticated) {
        navigate("/login");
        return null; 
    }

    return <Outlet />;
};
