import { closestCorners, DndContext } from "@dnd-kit/core";
import AddQuizPlayerComponent from "../../QuizPlayer/Components/AddQuizPlayerComponent";
import { UNASSIGNED_TEAM_ID } from "../../Shared/Constants/UnassignedTeamId";
import { QuizTeamManagerProps } from "../Props/QuizTeamManagerProps";
import { Button, IconButton, TextField, Tooltip } from "@mui/material";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import { useState } from "react";
import QuizTeamCard from "./QuizTeamCard";
import { QuizTeamWithQuizPlayers } from "../../../interfaces/QuizTeam/QuizTeamWithQuizPlayers";
import EmailIcon from '@mui/icons-material/Email';
import ShuffleIcon from '@mui/icons-material/Shuffle';
import RandomizeTeamsDialog from "./RandomizeTeamDialog";

const QuizTeamManagerFullScreen: React.FC<QuizTeamManagerProps> = ({
  quizTeams,
  onQuizPlayerDeleteInAssignedTeam,
  onQuizPlayerDeleteInUnassignedTeam,
  moveQuizPlayerToTeam,
  onDeleteQuizTeam,
  onAddGuestQuizTeam,
  onAddClickQuizPlayerGuest,
  onAddClickQuizPlayerUserMultiSelect,
  onAddClickQuizPlayerWithEmail,
  quizPlayerUsersNotAttachedToQuiz,
  onSendInvitationMailQuizPlayers,
  onRandomizeQuizTeams,
  onQuizTeamChange
}) => {
  const [guestQuizTeamName, setGuestQuizTeamName] = useState<string>();
  const [showRandomizeDialog, setShowRandomizeDialog] = useState<boolean>(false);

  async function onAddGuestQuizTeamClick() {
    await onAddGuestQuizTeam(guestQuizTeamName);
    setGuestQuizTeamName("");
  }

  const handleDragEndQuizPlayer = async (event) => {
    const { active, over } = event;
    if (!over) return;
  
    const fromTeamIndex = quizTeams.findIndex((quizTeam) =>
      quizTeam.quizPlayers.some((quizPlayer) => quizPlayer.id === active.id)
    );
    const toTeamIndex = quizTeams.findIndex((quizTeam) => quizTeam.id === over.id);
  
    if (fromTeamIndex === toTeamIndex) return;
  
    const fromTeam = { ...quizTeams[fromTeamIndex] } as QuizTeamWithQuizPlayers;
    const toTeam = { ...quizTeams[toTeamIndex] } as QuizTeamWithQuizPlayers;
  
    await moveQuizPlayerToTeam(active.id, fromTeam.id, toTeam.id);
  };

  function handleSendMailsClick(){
    onSendInvitationMailQuizPlayers(quizTeams.flatMap(quizTeam => quizTeam.quizPlayers));
  }

  function handleOnRandomizeTeamActionButtonClick(){
    setShowRandomizeDialog(true);
  }

  function handleOnRandomizeTeams(randomizedQuizTeams: QuizTeamWithQuizPlayers[]) {
    onRandomizeQuizTeams(randomizedQuizTeams);
  }
  return (
    <>
        <div id="quiz-team-manager-container-fullscreen">
      <AddQuizPlayerComponent
        quizPlayerUsersNotAttachedToQuiz={quizPlayerUsersNotAttachedToQuiz}
        onAddClickQuizPlayerGuest={onAddClickQuizPlayerGuest}
        onAddClickQuizPlayerUserMultiSelect={onAddClickQuizPlayerUserMultiSelect}
        onAddClickQuizPlayerWithEmail={onAddClickQuizPlayerWithEmail}
      />

      <div id="quiz-team-actions-container">
        <div id="quiz-team-actions-bar">
          <Tooltip title="Verstuur Mails">
              <IconButton className="action-button" onClick={handleSendMailsClick}>
                <EmailIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Randomize teams">
              <IconButton className="action-button" onClick={handleOnRandomizeTeamActionButtonClick}>
                <ShuffleIcon />
              </IconButton>
            </Tooltip>
        </div>
      </div>

      <DndContext onDragEnd={handleDragEndQuizPlayer} collisionDetection={closestCorners}>
        <div id="unassigned-team-container">
          <QuizTeamCard
            quizTeam={quizTeams.find((quizTeam) => quizTeam.id === UNASSIGNED_TEAM_ID)}
            onQuizPlayerDelete={onQuizPlayerDeleteInUnassignedTeam}
            onQuizTeamDelete={async () => {}}
            onSendInvitationEmailQuizPlayers={onSendInvitationMailQuizPlayers}
            onQuizTeamChange={() => {}}
          />
        </div>

        <div id="quiz-teams-container">
          {quizTeams
            .filter((quizTeam) => quizTeam.id !== UNASSIGNED_TEAM_ID)
            .map((quizTeam) => (
              <QuizTeamCard
                key={quizTeam.id}
                quizTeam={quizTeam}
                onQuizPlayerDelete={onQuizPlayerDeleteInAssignedTeam}
                onQuizTeamDelete={onDeleteQuizTeam}
                onSendInvitationEmailQuizPlayers={onSendInvitationMailQuizPlayers}
                onQuizTeamChange={onQuizTeamChange}
              />
            ))}
        </div>
      </DndContext>

      <div id="add-quiz-team-container">
        <div id="add-guest-quiz-team">
            <TextField
              label="Toevoegen gast team"
              variant="outlined"
              value={guestQuizTeamName}
              fullWidth
              onChange={(e) => setGuestQuizTeamName(e.target.value)}
            />
          <Button variant="contained" onClick={onAddGuestQuizTeamClick}>
            <GroupAddIcon />
          </Button>
        </div>
      </div>
    </div>

        <RandomizeTeamsDialog quizPlayers={quizTeams.find((quizTeam) => quizTeam.id === UNASSIGNED_TEAM_ID).quizPlayers}
          onRandomize={handleOnRandomizeTeams}
          open={showRandomizeDialog} onClose={() => {setShowRandomizeDialog(false)}}/>
    </>
  );
};

export default QuizTeamManagerFullScreen;