import { QuizPlayerCardProps } from "../Props/QuizPlayerCardProps";
import { DraggableQuizPlayerCard } from "./DraggableQuizPlayerCard";
import { NonDraggableQuizPlayerCard } from "./NonDraggableQuizPlayerCard";

export const QuizPlayerCard: React.FC<QuizPlayerCardProps> = ({ quizPlayer, onPlayerDelete, isDraggable, onSendInvitationEmailQuizPlayer}) => {
  if (isDraggable) {
    return <DraggableQuizPlayerCard quizPlayer={quizPlayer} onPlayerDelete={onPlayerDelete} isDraggable={isDraggable} onSendInvitationEmailQuizPlayer={onSendInvitationEmailQuizPlayer}/>;
  } else {
    return <NonDraggableQuizPlayerCard quizPlayer={quizPlayer} onPlayerDelete={onPlayerDelete} isDraggable={isDraggable} onSendInvitationEmailQuizPlayer={onSendInvitationEmailQuizPlayer}/>;
  }
};
