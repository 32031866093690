import React from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { QuestionForRoundSelectorProps } from '../Properties/QuestionForRoundSelectorProps';

const QuestionForRoundSelector: React.FC<QuestionForRoundSelectorProps> = ({ quiz, selectedRound, handleRoundChange }) => {
  return (
    <FormControl fullWidth>
      <InputLabel>Nieuwe ronde voor vraag</InputLabel>
      <Select
        value={selectedRound.id}
        onChange={handleRoundChange}
        label="Nieuwe ronde voor vraag"
      >
        {quiz.rounds.map(round => (
          <MenuItem key={round.id} value={round.id}>
            {round.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default QuestionForRoundSelector;