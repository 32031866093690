import { QuizTeam } from "../../../interfaces/QuizTeam/QuizTeam";
import { QuizTeamWithQuizPlayers } from "../../../interfaces/QuizTeam/QuizTeamWithQuizPlayers";
import { CreateQuizTeam as createQuizTeam } from '../../../interfaces/QuizTeam/CreateQuizTeam';
import { GetNewGuestQuizTeam } from "./QuizTeamManagerHelper";
import { QuizTeamRepo } from "../Repo/QuizTeamRepo";
import { QuizPlayer } from "../../../interfaces/QuizPlayer/QuizPlayer";

export function randomizeQuizTeams(quizPlayers: QuizPlayer[], amountOfTeams: number, setEveryPlayerATeam: boolean){
    let quizTeams = generateEmptyTeams(amountOfTeams);
    let currentTeamIndex = 0;

    while(quizPlayers.length > 0)
    {
        const randomQuizPlayerIndex =  Math.floor(Math.random() * (quizPlayers.length));
        const randomQuizPlayer = quizPlayers.splice(randomQuizPlayerIndex, 1)[0];
        const currentTeam = quizTeams[currentTeamIndex];

        currentTeam.quizPlayers.push(randomQuizPlayer);
        currentTeam.name = (setEveryPlayerATeam ? randomQuizPlayer.quizPlayerName : `Team ${currentTeamIndex + 1}`)

        currentTeamIndex = (currentTeamIndex == (quizTeams.length -1) ? 0 : currentTeamIndex + 1);
    }

    return quizTeams;
}

export function generateEmptyTeams(amountOfTeams: number): QuizTeamWithQuizPlayers[] {
    return Array.from({ length: amountOfTeams }, (_) => ({
        name: "",
        quizPlayers: []
    } as QuizTeamWithQuizPlayers));
}

export function generateTeamDistribution(teams: number, totalPlayers: number) {
    let distribution: number[] = [];
    let playersLeft = totalPlayers;

    for (let i = 0; i < teams; i++) {
      const playersInTeam = Math.floor(playersLeft / (teams - i)); 
      distribution.push(playersInTeam);
      playersLeft -= playersInTeam;
    }

    return distribution.reverse();
};

export async function createRandomizedQuizTeams(quizId: number, quizTeams: QuizTeamWithQuizPlayers[], quizTeamRepo: QuizTeamRepo): Promise<QuizTeamWithQuizPlayers[]> {
    let newQuizTeams: QuizTeamWithQuizPlayers[] = [];
  
    const createQuizTeamPromises = quizTeams.map(async (quizTeam) => {
      const createQuizTeam = {
        QuizTeamName: quizTeam.name,
        Sequence: 0,
        QuizPlayerIds: quizTeam.quizPlayers.map(quizPlayer => quizPlayer.id)
      } as createQuizTeam;
  
      return new Promise<QuizTeam>((resolve) => {
        quizTeamRepo.CreateQuizTeam(
          (createdQuizTeam: QuizTeam) => {
            const newGuestQuizTeam = GetNewGuestQuizTeam(
              createdQuizTeam.id,
              quizTeam.name,
              quizTeam.quizPlayers
            );
  
            newQuizTeams.push(newGuestQuizTeam);
            resolve(createdQuizTeam);
          },
          quizId,
          createQuizTeam
        );
      });
    });
  
    await Promise.all(createQuizTeamPromises);
    return newQuizTeams;
  }
  