import { Button, Tooltip } from '@mui/material';
import { Round } from '../../../interfaces/Quiz/Round';
import AddIcon from '@mui/icons-material/Add';
import SortableRound from './SortableRound';
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';
import { useEffect } from "react"
import {
    PointerSensor,
    useSensor,
    useSensors,
    DragEndEvent,
    TouchSensor,
    MeasuringStrategy,
    DndContext,
    closestCenter
} from "@dnd-kit/core"
import {
    arrayMove,
    AnimateLayoutChanges,
    defaultAnimateLayoutChanges,
    SortableContext,
    verticalListSortingStrategy
} from "@dnd-kit/sortable"
import { RoundSequence } from '../../../interfaces/Round/RoundSequence';
import { useAuth } from 'react-oidc-context';
import { RoundRepo } from '../Repo/RoundRepo';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { RoundListProps } from '../Properties/RoundListProps';
import { CreateOrEditRound } from '../../../interfaces/Round/CreateOrEditRound';
import { getDateTimeString } from '../../Shared/Date/DateHelper';
import { QuizRepo } from '../../Quiz/Repo/QuizRepo';


function RoundList({ quiz, selectedRound, onRoundClick, onAddRound, onReorderRounds, onDeleteRound, onRoundChange }: RoundListProps) {
    const auth = useAuth();
    const quizRepo = new QuizRepo(auth);
    const roundRepo = new RoundRepo(auth);
    const sensors = useSensors(useSensor(PointerSensor), useSensor(TouchSensor));
    
    useEffect(() => {
    }, [auth])

    function handleRoundClick(round: Round) {
        onRoundClick(round)
    }

    function handleAddRoundClick() {
        onAddRound();
    }

    function handleOnDeleteRound(roundId: number) {
        onDeleteRound(roundId);
    }

    function handleSetRounds(rounds: Round[]) {
        onReorderRounds(rounds);
    }

      const handleOnGeneratePowerpointClick = () => {
        const fileName = getDateTimeString(new Date()) + "_" + quiz.name + ".pptx";
        quizRepo.GeneratePowerpoint(() => {
        },
          quiz.id ?? 0, fileName)
      }

    // Desktop and Mobile
    const animateLayoutChanges: AnimateLayoutChanges = (args) => defaultAnimateLayoutChanges({ ...args, wasDragging: true })
    const measuringConfig = {
        droppable: {
            strategy: MeasuringStrategy.Always
        }
    };

    const onRoundChanged = (createOrEditRound:CreateOrEditRound) => {
        onRoundChange(createOrEditRound);
    }
    
    const handleDragEnd = async (event: DragEndEvent) => {
        const { active, over } = event;
        if (!over) return;

        const activeRound = quiz.rounds.find((r) => r.id == active.id)
        const overRound = quiz.rounds.find((r) => r.id == over.id)

        if (!activeRound || !overRound) {
            return;
        }

        const activeIndex = quiz.rounds.findIndex((r) => r.id == activeRound.id)
        const overIndex = quiz.rounds.findIndex((r) => r.id == overRound.id)

        if (activeIndex !== overIndex) {
            const newArray = arrayMove(quiz.rounds, activeIndex, overIndex);

            const roundSequence: RoundSequence = {
                sequencedRoundIds: newArray.map((round) => round.id)
            };

            handleSetRounds(newArray);
            await roundRepo.CalculateRoundSequencesAsync(roundSequence, quiz.id);
        }
    }

    return (
        <>
        <div>
            <Tooltip title="Genereer PowerPoint" arrow className="round-list-element generate-powerpoint-button">
                <Button variant="contained" onClick={handleOnGeneratePowerpointClick} >
                  <AutoAwesomeMotionIcon />
                </Button>
              </Tooltip>
        </div>
            {
                quiz.rounds.length > 0 &&
                <div id="round-list">
                    <DndContext
                        sensors={sensors}
                        collisionDetection={closestCenter}
                        onDragEnd={handleDragEnd}
                        measuring={measuringConfig}
                        modifiers={[restrictToVerticalAxis]}
                    >
                        <SortableContext
                            items={quiz.rounds}
                            strategy={verticalListSortingStrategy}
                        >
                            {
                                quiz.rounds.map((r) => (
                                    <SortableRound
                                        key={r.id}
                                        round={r}
                                        selectedRound={selectedRound}
                                        onRoundClick={handleRoundClick}
                                        onDeleteRound={handleOnDeleteRound}
                                        onRoundChange={onRoundChanged}
                                    />
                                ))
                            }
                        </SortableContext>
                    </DndContext>
                </div>}

            <div>
                <Button
                    variant="outlined"
                    startIcon={<AddIcon />}
                    fullWidth
                    className="round-list-element"
                    onClick={handleAddRoundClick}
                >
                </Button>
            </div>
        </>
    );
}

export default RoundList;