import { arrayMove } from "@dnd-kit/sortable";
import { UpdateQuestion } from "../../../interfaces/Questions/UpdateQuestion";
import { Question } from "../../../interfaces/Quiz/Question";
import { QuestionFromRound } from "../../../interfaces/Quiz/QuestionFromRound";
import { Quiz } from "../../../interfaces/Quiz/Quiz";
import { Round } from "../../../interfaces/Quiz/Round";
import { Attachment } from "../../Shared/Components/FileUpload/interface/Attachment";
import { Answer } from "../../../interfaces/Quiz/Answer";

export function UpdateQuizWithDeletedQuestion(quiz: Quiz, round: Round, questionId: number): Quiz | null {
  const roundIndex = quiz.rounds.findIndex(x => x.id === round.id);
  if (roundIndex === -1) return null;

    const updatedQuestionsFromRound = quiz.rounds[roundIndex].questionsFromRound.filter(x => x.id !== questionId);
    updatedQuestionsFromRound.forEach((question: QuestionFromRound, index: number) => {
        question.sequence = index + 1
    })
    const updatedRound = {
        ...quiz.rounds[roundIndex],
        questionsFromRound: updatedQuestionsFromRound
    };

  const updatedRounds = quiz.rounds.map((round, index) => index === roundIndex ? updatedRound : round);

  const updatedQuiz = {
    ...quiz,
    rounds: updatedRounds
  };

  return updatedQuiz;
}

export function UpdateQuizWithUpdatedRoundAndUpdatedQuestion(
  quiz: Quiz,
  selectedRound: Round,
  newQuestion: Question,
  calculatedSequence: number,
  answerId: number
): Quiz | null {

  const selectedRoundIndex = quiz.rounds.findIndex(x => x.id === selectedRound.id);
  if (selectedRoundIndex === -1) return quiz;

  const updatedQuestionFromRound = [
    ...selectedRound.questionsFromRound,
    {
      id: newQuestion.id,
      introductionText: newQuestion.introductionText,
      questionText: newQuestion.questionText,
      sequence: calculatedSequence,
      maxPoints: 0,
      answers: [{
        id: answerId,
        description: ""
      } as Answer],
      attachments: [] as Attachment[],
      quizId: quiz.id,
      roundId: selectedRound.id
    } as QuestionFromRound
  ];

  const updatedRound = {
    ...quiz.rounds[selectedRoundIndex],
    questionsFromRound: updatedQuestionFromRound
  };

  const updatedRounds = quiz.rounds.map((round, index) => index === selectedRoundIndex ? updatedRound : round);

  const updatedQuiz = {
    ...quiz,
    rounds: updatedRounds
  };

  return updatedQuiz;
}

export function UpdateQuizWithDeletedAttachment(quiz: Quiz, roundId: number, questionId: number, attachmentId: number): Quiz {
  let roundIndex = quiz.rounds.findIndex(x => x.id == roundId);
  let round = quiz.rounds[roundIndex];

  let questionIndex = round?.questionsFromRound.findIndex(x => x.id == questionId);
  let question = round.questionsFromRound[questionIndex];

  let attachmentWithoutDeleted = question.attachments.filter(attachment => attachment.attachmentId != attachmentId);
  let updatedQuestion = { ...question, attachments: attachmentWithoutDeleted };

  let updatedQuestions = round.questionsFromRound.map(question => {
    if (question.id == questionId) return updatedQuestion
    else return question
  })

  let updatedRounds = quiz.rounds.map(round => {
    if (round.id == roundId) return { ...round, questionsFromRound: updatedQuestions }
    else return round
  })

  let updatedQuiz = { ...quiz, rounds: updatedRounds }
  return updatedQuiz;
}

export function UpdateQuizWithAttachmentAdd(quiz: Quiz, roundId: number, questionId: number, attachment: Attachment): Quiz {
  let roundIndex = quiz.rounds.findIndex(x => x.id == roundId);
  let round = quiz.rounds[roundIndex];

  let questionIndex = round?.questionsFromRound.findIndex(x => x.id == questionId);
  let question = round.questionsFromRound[questionIndex];

  let updatedAttachments = [...question.attachments, attachment];
  let updatedQuestion = { ...question, attachments: updatedAttachments };

  let updatedQuestions = round.questionsFromRound.map(question => {
    if (question.id == questionId) return updatedQuestion
    else return question
  })

  let updatedRounds = quiz.rounds.map(round => {
    if (round.id == roundId) return { ...round, questionsFromRound: updatedQuestions }
    else return round
  })

  let updatedQuiz = { ...quiz, rounds: updatedRounds }
  return updatedQuiz;
}

export function UpdateQuizWithUpdatedRoundAndQuestionChange(quiz: Quiz, selectedRound: Round, question: UpdateQuestion): Quiz | null {
  const round = findRound(quiz, selectedRound.id);
  if (!round) return null;

  const questionIndex = round.questionsFromRound.findIndex(x => x.id === question.id);
  if (questionIndex === -1) return null;

  const newRoundForQuestion = findRound(quiz, question.roundId);

  let updatedQuestionsFromSelectedRound = [...round.questionsFromRound];
  updatedQuestionsFromSelectedRound[questionIndex] = updateQuestion(updatedQuestionsFromSelectedRound[questionIndex], question);

  if (question.roundId !== selectedRound.id && newRoundForQuestion) {
    updatedQuestionsFromSelectedRound.splice(questionIndex, 1);
    newRoundForQuestion.questionsFromRound.push({ ...question, roundId: question.roundId });

    newRoundForQuestion.questionsFromRound = reorderQuestions(newRoundForQuestion.questionsFromRound);
    updatedQuestionsFromSelectedRound = reorderQuestions(updatedQuestionsFromSelectedRound);
  }

  const updatedRound = {
    ...round,
    questionsFromRound: updatedQuestionsFromSelectedRound
  };

  return updateQuiz(quiz, updatedRound, newRoundForQuestion);
}

export function GenerateUpdatedQuizObjectWithNewSequence(foundRound: Round, activeIndex: number, overIndex: number, quiz: Quiz, roundIndex: number) {
  const newArray = arrayMove(foundRound.questionsFromRound, activeIndex, overIndex);

  for (let i = 0; i < newArray.length; i++) {
    newArray[i].sequence = i + 1;
  }

  const updatedRound = {
    ...foundRound,
    questionsFromRound: newArray
  };

  const updatedQuiz = {
    ...quiz,
    rounds: quiz.rounds.map((round, index) => index === roundIndex ? updatedRound : round
    )
  };
  return { updatedQuiz, newArray };
}

function findRound(quiz: Quiz, roundId: number): Round | undefined {
  return quiz.rounds.find(x => x.id === roundId);
}

function updateQuestion(question: QuestionFromRound, update: UpdateQuestion): QuestionFromRound {
  return {
    ...question,
    introductionText: update.introductionText,
    questionText: update.questionText,
    sequence: update.sequence,
    answers: update.answers,
    attachments: update.attachments
  };
}

function reorderQuestions(questions: QuestionFromRound[]): QuestionFromRound[] {
  return questions.map((q, index) => ({
    ...q,
    sequence: index + 1
  }));
}

function updateQuiz(quiz: Quiz, updatedRound: Round, newRoundForQuestion: Round | undefined): Quiz {
  return {
    ...quiz,
    rounds: quiz.rounds.map(r => {
      if (r.id === updatedRound.id) return updatedRound;
      if (newRoundForQuestion && r.id === newRoundForQuestion.id) return newRoundForQuestion;
      return r;
    })
  };
}