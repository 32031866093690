import { FC, useState } from 'react';
import { DataGrid, GridColDef, GridNoRowsOverlay, GridRowParams } from '@mui/x-data-grid';

const StyledDataGrid: FC<{dataset:any[], columns:GridColDef[], children:any, onRowDoubleClick: (params: GridRowParams) => void, noRowsText:string}> = ({dataset, columns, children, onRowDoubleClick, noRowsText}) => {

    return (
        <>
         <DataGrid
            sx={{
              border: 0
            }}
            onRowDoubleClick={onRowDoubleClick}
            rows={dataset} columns={columns}
            hideFooter
            autoHeight
            localeText={{
              noRowsLabel: noRowsText,
            }}
            initialState={{
              columns: {
                columnVisibilityModel: {
                  id: false,
                },
              },
              sorting: {
                sortModel: [{ field: 'startDate', sort: 'desc' }],
              }
            }}
          />
        </>
   )
}


export default StyledDataGrid;