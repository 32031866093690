import { Answer } from "../../interfaces/Quiz/Answer";
import { Quiz } from "../../interfaces/Quiz/Quiz";

export function UpdateQuizWithDeleteAnswer(quiz: Quiz, roundId: number, questionId: number, answerId: number): Quiz {
  let roundIndex = quiz.rounds.findIndex(x => x.id == roundId);
  let round = quiz.rounds[roundIndex];

  let questionIndex = round?.questionsFromRound.findIndex(x => x.id == questionId);
  let question = round.questionsFromRound[questionIndex];

  let updatedAnswers = question.answers.filter(answer => answer.id != answerId);
  let updatedQuestion = { ...question, answers: updatedAnswers };

  let updatedQuestions = round.questionsFromRound.map(question => {
    if (question.id == questionId) return updatedQuestion
    else return question
  })

  let updatedRounds = quiz.rounds.map(round => {
    if (round.id == roundId) return { ...round, questionsFromRound: updatedQuestions }
    else return round
  })

  let updatedQuiz = { ...quiz, rounds: updatedRounds }
  return updatedQuiz;
}

export function UpdateQuizWithUpdatedAnswer(quiz: Quiz, roundId: number, questionId: number, answerId: number, answerDescription: string): Quiz {
  let roundIndex = quiz.rounds.findIndex(x => x.id == roundId);
  let round = quiz.rounds[roundIndex];

  let questionIndex = round?.questionsFromRound.findIndex(x => x.id == questionId);
  let question = round.questionsFromRound[questionIndex];

  let updatedAnswer = { id: answerId, description: answerDescription } as Answer;

  let updatedAnswers = question.answers.map(answer => {
    if (answer.id == answerId) return updatedAnswer;
    else return answer
  })
  let updatedQuestion = { ...question, answers: updatedAnswers };

  let updatedQuestions = round.questionsFromRound.map(question => {
    if (question.id == questionId) return updatedQuestion
    else return question
  })

  let updatedRounds = quiz.rounds.map(round => {
    if (round.id == roundId) return { ...round, questionsFromRound: updatedQuestions }
    else return round
  })

  let updatedQuiz = { ...quiz, rounds: updatedRounds } as Quiz;
  return updatedQuiz;
}

export function UpdateQuizWithCreateAnswer(quiz: Quiz, roundId: number, questionId: number, answerId: number): Quiz {
  let roundIndex = quiz.rounds.findIndex(x => x.id == roundId);
  let round = quiz.rounds[roundIndex];

  let questionIndex = round?.questionsFromRound.findIndex(x => x.id == questionId);
  let question = round.questionsFromRound[questionIndex];

  let newAnswer = { id: answerId, description: "" } as Answer;

  let updatedQuestion = {
    ...question,
    answers: [
      ...question.answers,
      { ...newAnswer, questionId: question.id }
    ]
  };

  let updatedQuestions = round.questionsFromRound.map(question => {
    if (question.id == questionId) return updatedQuestion
    else return question
  })

  let updatedRounds = quiz.rounds.map(round => {
    if (round.id == roundId) return { ...round, questionsFromRound: updatedQuestions }
    else return round
  })

  let updatedQuiz = { ...quiz, rounds: updatedRounds }
  return updatedQuiz;
}