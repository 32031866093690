import React from 'react';
import { Snackbar } from '@mui/material';
import { ErrorSnackbarProps } from '../../../interfaces/Error/ErrorSnackbarProps';


const ErrorSnackbar: React.FC<ErrorSnackbarProps> = (
    { errors }
) => {
    return (
        <Snackbar open={errors.length > 0}
            autoHideDuration={5000}
            message={
                <ul>
                    {errors.map((error, index) =>
                        (<li key={index}>{error.error}</li>))} </ul>
            }
        />
    );
};

export default ErrorSnackbar