import {
    Autocomplete,
    Button,
    Checkbox,
    TextField,
  } from "@mui/material";
  import SendIcon from "@mui/icons-material/Send";
  import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
  import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
  import { useState } from "react";
  import { AddQuizPlayerComponentProps } from "../Props/AddQuizPlayerComponentProps";
  import '../Styles/AddQuizPlayerComponent.scss'; 
import { IsEmailValid } from "../../Shared/Email/EmailHelper";
import { QuizPlayerUser } from "../../../interfaces/QuizPlayer/QuizPlayerUser";
  
  const AddQuizPlayerComponent: React.FC<AddQuizPlayerComponentProps> = ({ 
    quizPlayerUsersNotAttachedToQuiz, 
    onAddClickQuizPlayerGuest, 
    onAddClickQuizPlayerUserMultiSelect, 
    onAddClickQuizPlayerWithEmail 
  }) => {
    const [selectedQuizPlayerUsers, setSelectedQuizPlayerUsers] = useState<QuizPlayerUser[]>([]);
    const [inviteEmail, setInviteEmail] = useState<string>('');
    const [guestQuizPlayerName, setGuestQuizPlayerName] = useState<string>('');
    const [error, setError] = useState<string | null>(null);
  
    const handleAutocompleteQuizPlayerUsersChange = (
      event: React.SyntheticEvent<Element, Event>,
      value: QuizPlayerUser[],
    ) => {
      setSelectedQuizPlayerUsers(value);
    };
  
    const handleOnAutoCompleteQuizPlayerUserClick = async () => {
      await onAddClickQuizPlayerUserMultiSelect(selectedQuizPlayerUsers);
      setSelectedQuizPlayerUsers([]);
    };
  
    const handleOnEmailInviteClick = async () => {
      if (!inviteEmail || !IsEmailValid(inviteEmail)) {
        setError('Ongeldig emailadres.');
        return;
      }
  
      await onAddClickQuizPlayerWithEmail(inviteEmail);
      setInviteEmail('');
      setError(null);
    };
  
    const onAddGuestClick = async () => {
      if (!guestQuizPlayerName.trim()) {
        setError('Gastnaam mag niet leeg zijn');
        return;
      }
  
      await onAddClickQuizPlayerGuest(guestQuizPlayerName.trim());
      setGuestQuizPlayerName(''); 
      setError(null);
    };
  
    return (
      <div id="add-quiz-player-container">
        <div id="add-existing-player-container">
          <Autocomplete
            multiple
            fullWidth
            options={quizPlayerUsersNotAttachedToQuiz}
            disableCloseOnSelect
            value={selectedQuizPlayerUsers}
            getOptionLabel={(quizPlayerUser) => quizPlayerUser.user.userName}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox checked={selected} />
                {option.user.userName}
              </li>
            )}
            renderInput={(params) => (
              <TextField {...params} label="Selecteer spelers" placeholder="Spelers" />
            )}
            onChange={handleAutocompleteQuizPlayerUsersChange}
          />
          <Button variant="contained" onClick={handleOnAutoCompleteQuizPlayerUserClick}>
            <PersonAddAlt1Icon />
          </Button>
        </div>
  
        <div id="invite-player-container">
          <TextField
            label="Nieuwe speler uitnodigen via email"
            variant="outlined"
            value={inviteEmail}
            fullWidth
            onChange={(e) => setInviteEmail(e.target.value)}
          />
          <Button variant="contained" onClick={handleOnEmailInviteClick}>
            <SendIcon />
          </Button>
        </div>

        {error && (
          <div id="error-message">
            {error}
          </div>
        )}
  
        <div id="add-guest-container">
          <TextField
            label="Toevoegen gast"
            variant="outlined"
            value={guestQuizPlayerName}
            inputProps={{ maxLength: 64 }}
            fullWidth
            onChange={(e) => setGuestQuizPlayerName(e.target.value)}
          />
          <Button variant="contained" onClick={onAddGuestClick}>
            <PersonAddAltIcon />
          </Button>
        </div>
      </div>
    );
  };
  
  export default AddQuizPlayerComponent;