import { useDroppable } from "@dnd-kit/core";
import { QuizPlayerCard } from "../../QuizPlayer/Components/QuizPlayerCard";
import { QuizTeamCardProps } from "../Props/QuizTeamCardProps";
import { UNASSIGNED_TEAM_ID } from "../../Shared/Constants/UnassignedTeamId";
import { QuizPlayer } from "../../../interfaces/QuizPlayer/QuizPlayer";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { IconButton } from "@mui/material";
import { useState } from "react";
import DeleteModal from "../../Shared/Delete/DeleteModal";
import SendIcon from "@mui/icons-material/Send";
import EditQuizTeam from "./EditQuizTeam";
import { QuizTeam } from "../../../interfaces/QuizTeam/QuizTeam";

const QuizTeamCard: React.FC<QuizTeamCardProps> = ({ quizTeam, onQuizPlayerDelete, onQuizTeamDelete, onSendInvitationEmailQuizPlayers, onQuizTeamChange}) => {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showEditQuizTeamDialog, setShowQuizTeamDialog] = useState<boolean>(false);

  const { isOver, setNodeRef } = useDroppable({ id: quizTeam.id });
  const isUnassignedTeam = quizTeam.id === UNASSIGNED_TEAM_ID;

  const handleQuizTeamChange = (quizTeam: QuizTeam) => {
    onQuizTeamChange(quizTeam)
  }

  const onDeletePlayer = (quizPlayer: QuizPlayer) => {
    onQuizPlayerDelete(quizPlayer.id, quizTeam.id);
  };

  const handleOnEditTeaButtonClick = () => {
    setShowQuizTeamDialog(true);
  }

  const handleOnDeleteButtonClick = () => {
    setShowConfirmationModal(true);
  }

  const handleOnQuizTeamDeleteCallback = () => {
    onQuizTeamDelete(quizTeam);
    setShowConfirmationModal(false);
  }

  const handleSendQuizPlayerInvitationEmail = (quizPlayer: QuizPlayer) => {
    onSendInvitationEmailQuizPlayers([quizPlayer])
  }

  const handleSendQuizPlayersInvitationEmail = () => {
    onSendInvitationEmailQuizPlayers(quizTeam.quizPlayers);
  }

  return (
    <>
    <div
      ref={setNodeRef}
      className={`quiz-team-card 
                  ${isOver ? 'quiz-team-card-quiz-player-over' : ''} 
                  ${isUnassignedTeam ? 'quiz-team-card-unassigned' : 'quiz-team-card-assigned'}
                  ${quizTeam.teamId != null ? "quiz-team-card-existing" : "quiz-team-card-guest"}`}
    >
        <div className="quiz-team-header">
          <div className="title">
            {isUnassignedTeam ? "Niet toegewezen" : quizTeam.name}
          </div>

          <div className={`quiz-team-hover-controls ${isUnassignedTeam ? 'unassigned' : ''}`}>
            {!isUnassignedTeam && (
              <IconButton aria-label="Edit" onClick={handleOnEditTeaButtonClick}>
                <EditIcon />
              </IconButton>
            )}
            <IconButton aria-label="Send" onClick={handleSendQuizPlayersInvitationEmail}>
              <SendIcon />
            </IconButton>
            {!isUnassignedTeam && (
              <IconButton aria-label="Delete" onClick={handleOnDeleteButtonClick}>
                <DeleteIcon />
              </IconButton>
            )}
          </div>
        </div>

      <div className="quiz-players">
        {quizTeam.quizPlayers.length > 0 ? (
          quizTeam.quizPlayers.map((quizPlayer) => (
            <QuizPlayerCard key={quizPlayer.id} quizPlayer={quizPlayer} onPlayerDelete={onDeletePlayer} isDraggable={true} onSendInvitationEmailQuizPlayer={handleSendQuizPlayerInvitationEmail}/>
          ))
        ) : (
          <p>Geen Spelers</p>
        )}
      </div>
    </div>

    <EditQuizTeam quizTeam={quizTeam} open={showEditQuizTeamDialog}
      onClose={() => {setShowQuizTeamDialog(false)}} onQuizTeamChange={handleQuizTeamChange}/>

    <DeleteModal
        deleteCallback={handleOnQuizTeamDeleteCallback}
        setOpenModal={setShowConfirmationModal}
        openModal={showConfirmationModal}
        subject='Team'
        subjectValue={quizTeam.name}/>
    </>
  );
};

export default QuizTeamCard;
