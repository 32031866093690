import React, { useEffect, useState } from 'react';
import { Card, CardContent, TextField, IconButton, Snackbar, NativeSelect, SelectChangeEvent, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { QuestionFromRound } from '../../../../interfaces/Quiz/QuestionFromRound';
import { Quiz } from '../../../../interfaces/Quiz/Quiz';
import { Round } from '../../../../interfaces/Quiz/Round';
import { UpdateQuestion } from '../../../../interfaces/Questions/UpdateQuestion';
import { useAuth } from 'react-oidc-context';
import { QuizRepo } from '../../../Quiz/Repo/QuizRepo';
import DeleteModal from '../../../Shared/Delete/DeleteModal';
import { KindOfRoundChoices } from '../../../../interfaces/Round/KindOfRoundChoices';
import ErrorSnackbar from '../../../Shared/Errors/ErrorSnackbar';
import { CustomError } from '../../../../interfaces/Error/CustomError';
import { FileUploadStatus } from '../../../Shared/Components/FileUpload/Enum/FileUploadStatus';
import { AttachmentSource } from '../../../Shared/Components/FileUpload/Enum/AttachmentSource';
import FileUploader from '../../../Shared/Components/FileUpload/FileUploader';
import Answers from '../../../Answer/Components/Answers';
import QuestionForRoundSelector from '../QuestionForRoundSelector';
import useDebounce from '../../../Shared/Hooks/DebounceHook';

interface SortableQuestionProps {
  initialQuestion: QuestionFromRound;
  onDeleteQuestion: (questionId: number) => void;
  quiz: Quiz;
  descriptionRef: React.RefObject<HTMLInputElement | null>;
  selectedRound: Round;
  onAddAnswer: (questionId: number) => void;
  onUpdateAnswer: (questionId: number, descriptionId: string, answerId: number) => void;
  onDeleteAnswer: (answerId: number, questionId: number) => void;
  onAddUrlAttachment: (url: string, source: AttachmentSource, questionId: number) => void;
  onDeleteAttachment: (attachmentId: number, questionId: number) => void;
  onFileAttachmentUpload: (file: File, questionId: number) => Promise<FileUploadStatus>;
  onQuestionChange: (updatedQuestion: UpdateQuestion) => void;
}

const SortableQuestionDesktop: React.FC<SortableQuestionProps> = ({
  initialQuestion,
  onDeleteQuestion,
  quiz,
  descriptionRef,
  selectedRound,
  onQuestionChange,
  onAddUrlAttachment,
  onDeleteAttachment,
  onFileAttachmentUpload,
  onAddAnswer,
  onDeleteAnswer,
  onUpdateAnswer
}) => {

  const auth = useAuth();
  const quizRepo = new QuizRepo(auth);

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [question, setQuestion] = useState<QuestionFromRound>({...initialQuestion});
  const [introductionText, setIntroductionText] = useState(initialQuestion.introductionText);
  const [questionText, setQuestionText] = useState(initialQuestion.questionText);

  const [errors, setErrors] = useState<CustomError[]>([]);

  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: question.id,
    transition: {
      duration: 150,
      easing: 'cubic-bezier(0.25, 1, 0.5, 1)',
    }
  });

  const styles = {
    transform: CSS.Transform.toString(transform),
    transition: transition || undefined,
  };

  useEffect(() => {
    setQuestion({ ...initialQuestion });
  }, [quiz, initialQuestion]);


  const saveQuestionChanges = async (updatedQuestion: UpdateQuestion) => {
    try {
      await quizRepo.UpdateQuestion(() => {
        onQuestionChange(updatedQuestion);
        setErrors([]);
      }, updatedQuestion);
    } catch (error) {
      setErrors(error.response.data.errors);
    }
  };

  const onBlurQuestionIntroductionText = (event: React.FocusEvent<HTMLInputElement>) => {
    saveQuestionChanges({
      id: question.id,
      introductionText: event.target.value,
      questionText: question.questionText,
      sequence: question.sequence ?? 0,
      quizId: quiz.id,
      roundId: question.roundId,
      answers: question.answers,
      attachments: question.attachments,
      maxPoints: question.maxPoints
    });
  };

  const onBlurQuestionText = (event: React.FocusEvent<HTMLInputElement>) => {
    saveQuestionChanges({
      id: question.id,
      introductionText: question.introductionText,
      questionText: event.target.value,
      sequence: question.sequence ?? 0,
      quizId: quiz.id,
      roundId: question.roundId,
      answers: question.answers,
      attachments: question.attachments,
      maxPoints: question.maxPoints
    });
  };

  const handleRoundChange = (event: SelectChangeEvent<number>) => {
    const newRoundId = Number(event.target.value);
    setQuestion(prev => ({ ...prev, roundId: newRoundId }));
    saveQuestionChanges({
      ...question,
      roundId: newRoundId,
      quizId: quiz.id
    });
  };

  const handleFileUpload = (file: File): Promise<FileUploadStatus> => onFileAttachmentUpload(file, question.id);
  const handleDeleteAttachment = (attachmentId: number) => onDeleteAttachment(attachmentId, question.id);
  const handleAddUrlAttachment = (url: string, source: AttachmentSource) => onAddUrlAttachment(url, source, question.id);
  const handleDeleteAnswer = (answerId: number) => onDeleteAnswer(answerId, question.id);
  const handleUpdateAnswer = (answerId: number, description: string) => onUpdateAnswer(question.id, description, answerId);
  const handleAddAnswer = () => onAddAnswer(question.id);

  return (
    <>
      <div
        className="question-list-element"
        ref={setNodeRef}
        style={styles}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <Card variant="outlined" className="question-card">
          <CardContent className='question-card-content'>
            <div className="question-header">
              <div className="question-container-left">{question.sequence}</div>
              <div className="question-container-right">
                <IconButton
                  className={`question-container-button ${isHovered ? '' : "question-container-button-hidden"}`}
                  aria-label="Delete"
                  onClick={() => setShowConfirmationModal(true)}
                >
                  <DeleteIcon />
                </IconButton>
                <IconButton
                  className="round-container-drag"
                  color="primary"
                  aria-label="Drag"
                  {...attributes}
                  {...listeners}
                >
                  <DragIndicatorIcon />
                </IconButton>
              </div>
            </div>

            <TextField
              className='question-input-element'
              label="intro"
              variant="outlined"
              fullWidth
              multiline
              rows={3}
              value={introductionText}
              onChange={(e) => setIntroductionText(e.target.value)}
              onBlur={onBlurQuestionIntroductionText}
              inputRef={descriptionRef}
            />

            <TextField
              className='question-input-element'
              label="Vraag"
              variant="outlined"
              fullWidth
              value={questionText}
              onChange={(e) => setQuestionText(e.target.value)}
              onBlur={onBlurQuestionText}
              inputRef={descriptionRef}
            />

            <Answers 
              question={question}
              onAddNewAnswer={handleAddAnswer}
              onDeleteAnswer={handleDeleteAnswer}
              onUpdateAnswer={handleUpdateAnswer}
            />

            <QuestionForRoundSelector
              handleRoundChange={handleRoundChange}
              quiz={quiz}
              selectedRound={selectedRound}
            ></QuestionForRoundSelector>

            <FileUploader
              showDragAndDrop={true}
              showPreview={true}
              showYouTubeInput={true}
              attachments={question.attachments}
              maxAttachments={3}
              acceptedTypes={['image/jpeg', 'image/png', 'video/mp4', 'audio/mpeg', 'video/youtube']}
              onFileUploaded={handleFileUpload}
              onAddUrl={handleAddUrlAttachment}
              onDeleteAttachment={handleDeleteAttachment}
            />
          </CardContent>
        </Card>
      </div>

      <DeleteModal
        deleteCallback={() => onDeleteQuestion(question.id)}
        setOpenModal={setShowConfirmationModal}
        openModal={showConfirmationModal}
        subject="Vraag"
        subjectValue={question.questionText}
      />

      <ErrorSnackbar errors={errors} />
    </>
  );
}

export default SortableQuestionDesktop;
