import { useEffect } from "react";
import { QuestionSummaryListContainerProps } from "../../Properties/QuestionSummaryListConatinerProps";
import { QuestionFromRound } from "../../../../interfaces/Quiz/QuestionFromRound";
import { QuestionCompleted } from "../../Shared/QuestionHelper";

const QuestionSummaryList: React.FC<QuestionSummaryListContainerProps> = ({ Round, ActiveQuestion, HandleQuestionSelect }) => {
  useEffect(() => { },
    [ActiveQuestion]
  )

  return (
    <>
      <div className='question-summary-list-content'>
        {
          Round.questionsFromRound
            .sort((a: QuestionFromRound, b: QuestionFromRound) => a.sequence - b.sequence)
            .map(question => <>
              <div className={`question-summary-item 
                              ${ActiveQuestion?.id === question.id ? 'active-question-summary-item' : 
                                  QuestionCompleted(question) ? 'completed-question-summary-item' : 'incompleted-question-summary-item'}`}
                onClick={() => HandleQuestionSelect(question)}>
                {question.sequence}
              </div>
            </>)
        }
      </div></>
  );
};

export default QuestionSummaryList