import getApiClient from "../../Shared/ApiClient/AxiosClient";
import { AxiosInstance } from "axios";
import { AuthContextProps } from "react-oidc-context";
import { UserInfo } from "../../../interfaces/User/UserInfoInterface";

export class UserRepo {

  private readonly apiClient:AxiosInstance;
  
  constructor(auth:AuthContextProps) {
    this.apiClient = getApiClient(auth);
  }

    async GetUserInfo(callback: any, id: string): Promise<void> {
      await this.apiClient.get(`/user/${id}`)
        .then((res: any) => {
          let data = res.data as UserInfo;
          callback(data);
        })
    }
 
      async UpdateUserInfo(callback: any, id: string, userInfo: UserInfo): Promise<void> {
        await this.apiClient.put(`/user/${id}`, userInfo)
          .then((res:any) => callback());
      }
}