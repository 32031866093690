import { useEffect, useState } from 'react';
import { Card, CardContent, TextField, IconButton, Typography, Modal, Box, Button, SelectChangeEvent } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { QuizRepo } from '../../../Quiz/Repo/QuizRepo';
import { useAuth } from 'react-oidc-context';
import { QuestionFromRound } from '../../../../interfaces/Quiz/QuestionFromRound';
import { CreateOrEditQuestion } from '../../../../interfaces/Questions/CreateOrEditQuestion';
import { UpdateQuestion } from '../../../../interfaces/Questions/UpdateQuestion';
import CreateOrEditQuestionPopup from '../CreateOrEditQuestionPopup';
import DeleteModal from '../../../Shared/Delete/DeleteModal';
import { CustomError } from '../../../../interfaces/Error/CustomError';
import ErrorSnackbar from '../../../Shared/Errors/ErrorSnackbar';
import { SortableQuestionMobileProps } from '../../Properties/SortableQuestionMobileProps';
import FileUploader from '../../../Shared/Components/FileUpload/FileUploader';
import { FileUploadStatus } from '../../../Shared/Components/FileUpload/Enum/FileUploadStatus';
import { AttachmentSource } from '../../../Shared/Components/FileUpload/Enum/AttachmentSource';
import Answers from '../../../Answer/Components/Answers';
import QuestionForRoundSelector from '../QuestionForRoundSelector';

function SortableQuestionMobile({
  initialQuestion,
  quiz,
  descriptionRef,
  selectedRound,
  onDeleteQuestion,
  onQuestionChange,
  onAddAnswer,
  onDeleteAnswer,
  onUpdateAnswer,
  onAddUrlAttachment,
  onDeleteAttachment,
  onFileAttachmentUpload
}: SortableQuestionMobileProps) {

  const auth = useAuth();
  const quizRepo = new QuizRepo(auth);

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showCreateOrEditQuestionPopup, setShowCreateOrEditQuestionPopup] = useState(false);

  const [question, setQuestion] = useState<QuestionFromRound>(initialQuestion);
  const [questionInCreateOrEditPopup, setQuestionForCreateOrEditPopup] = useState<CreateOrEditQuestion>({} as CreateOrEditQuestion);
  const [errors, setErrors] = useState<CustomError[]>([]);

  useEffect(() => {
    setQuestion(initialQuestion);
  }, [quiz, questionInCreateOrEditPopup]);


  const handleSaveChanges = async () => {
    const updateQuestion: UpdateQuestion = {
      id: question.id,
      questionText: question.questionText ?? "",
      introductionText: question.introductionText ?? "",
      sequence: question.sequence ?? 0,
      quizId: quiz.id,
      roundId: selectedRound.id,
      answers: question.answers,
      attachments: question.attachments,
      maxPoints: question.maxPoints
    };

    try {
      await quizRepo.UpdateQuestion(() => {
        onQuestionChange(updateQuestion);
        setErrors([]);
      }, updateQuestion);
    } catch (error) {
      setErrors(error.response.data.errors);
    }
  };

  const handleQuestionTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuestion((prevQuestion) => ({
      ...prevQuestion,
      questionText: event.target.value,
    }));
  };

  const handleIntroductionTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuestion((prevQuestion) => ({
      ...prevQuestion,
      introductionText: event.target.value,
    }));
  };

  const onBlurQuestionText = () => {
    handleSaveChanges();
  };

  const onBlurIntroductionText = () => {
    handleSaveChanges();
  };

  const showCreateOrEditQuestionPopupHandler = () => {

    let createOrEditQuestion: CreateOrEditQuestion = {
      isNewQuestion: false,
      sequence: question.sequence,
      questionText: question.questionText,
      introductionText: question.introductionText,
      roundName: selectedRound.name,
      answers: question.answers,
      attachments: question.attachments,
      id: question.id,
      maxPoints: question.maxPoints,
      quizId: quiz.id,
      roundId: question.roundId
    }

    setQuestionForCreateOrEditPopup(createOrEditQuestion);
    setShowCreateOrEditQuestionPopup(true);
  }

  const handleFileUpload = async (file: File): Promise<FileUploadStatus> => {
    return await onFileAttachmentUpload(file, question.id);
  };

  const handleDeleteAttachment = async (attachmentId: number) => {
    onDeleteAttachment(attachmentId, question.id);
  }

  const handleAddUrlAttachment = async (url: string, source: AttachmentSource) => {
    onAddUrlAttachment(url, source, question.id);
  }

  const handleOnDeleteAnswer = (answerId: number) => {
    onDeleteAnswer(answerId, question.id);
  }

  const handleOnUpdateAnswer = (answerId: number, description: string) => {
    onUpdateAnswer(question.id, description, answerId);
  }

  const handleRoundChange = (event: SelectChangeEvent<number>) => {
    const newRoundId = Number(event.target.value);
    const updatedQuestion = { ...question, roundId: newRoundId , quizId: quiz.id} as UpdateQuestion;
    setQuestion(updatedQuestion);
    onQuestionChange(updatedQuestion)
  };


  const handleOnAddAnswer = (): void => {
    onAddAnswer(question.id);
  }

  return (
    <>
      <div className="question-list-element">
        <Card variant="outlined" className="question-card">
          <CardContent className='question-card-content'>
            <div className="question-header">
              <div className="question-container-left">
                {question.sequence}
              </div>
              <div className="question-container-right">
                <IconButton
                  className={`question-container-button`}
                  aria-label="Delete"
                  onClick={() => setShowConfirmationModal(true)}
                >
                  <DeleteIcon />
                </IconButton>

                <IconButton
                  className={`question-container-button`}
                  aria-label="Edit"
                  onClick={() => showCreateOrEditQuestionPopupHandler()}
                >
                  <EditIcon />
                </IconButton>

              </div>
            </div>

            <TextField
              className='question-input-element'
              label="intro"
              variant="outlined"
              fullWidth
              multiline
              rows={3}
              value={question.introductionText}
              onChange={handleIntroductionTextChange}
              onBlur={onBlurIntroductionText}
              inputRef={descriptionRef}
            />

            <TextField
              className='question-input-element'
              label="Vraag"
              variant="outlined"
              fullWidth
              value={question.questionText}
              onChange={handleQuestionTextChange}
              onBlur={onBlurQuestionText}
              inputRef={descriptionRef}
            />

            <QuestionForRoundSelector
              handleRoundChange={handleRoundChange}
              quiz={quiz}
              selectedRound={selectedRound}
            />

            <Answers
              question={question}
              onAddNewAnswer={handleOnAddAnswer}
              onDeleteAnswer={handleOnDeleteAnswer}
              onUpdateAnswer={handleOnUpdateAnswer} />

            <FileUploader
              showDragAndDrop={false}
              showPreview={true}
              showYouTubeInput={true}
              attachments={question.attachments}
              maxAttachments={3}
              acceptedTypes={['image/jpeg', 'image/png', 'video/mp4', 'audio/mpeg', 'video/youtube']}
              onFileUploaded={handleFileUpload}
              onAddUrl={handleAddUrlAttachment}
              onDeleteAttachment={handleDeleteAttachment}
            />
            <ErrorSnackbar errors={errors} />
          </CardContent>
        </Card>
      </div>


      <CreateOrEditQuestionPopup
        initialQuestion={questionInCreateOrEditPopup}
        shouldOpenPopup={showCreateOrEditQuestionPopup}
        selectedRound={selectedRound}
        quiz={quiz}
        onQuestionChange={onQuestionChange}
        setShouldOpenPopup={setShowCreateOrEditQuestionPopup}
      ></CreateOrEditQuestionPopup>

      <DeleteModal
        deleteCallback={() => onDeleteQuestion(question.id)}
        setOpenModal={setShowConfirmationModal}
        openModal={showConfirmationModal}
        subject='Vraag'
        subjectValue={question.questionText} />
    </>
  );
}

export default SortableQuestionMobile;
