import React, { useState, useEffect } from 'react';
import { Question } from '../../../interfaces/Quiz/Question';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, SelectChangeEvent, TextField } from '@mui/material';
import { CreateOrEditQuestion } from '../../../interfaces/Questions/CreateOrEditQuestion';
import { Quiz } from '../../../interfaces/Quiz/Quiz';
import { Round } from '../../../interfaces/Quiz/Round';

interface CreateOrEditQuestionPopupProps {
    onQuestionChange: (question: Question) => void;
    setShouldOpenPopup: (shouldOpen: boolean) => void;
    selectedRound: Round;
    shouldOpenPopup: boolean;
    initialQuestion: CreateOrEditQuestion;
    quiz: Quiz;
}

const CreateOrEditQuestionPopup: React.FC<CreateOrEditQuestionPopupProps> = ({
    onQuestionChange,
    setShouldOpenPopup,
    selectedRound,
    shouldOpenPopup,
    initialQuestion,
    quiz
}) => {
    const [question, setQuestion] = useState<CreateOrEditQuestion>({...initialQuestion});

    useEffect(() => {
        setQuestion(prev => ({ ...prev, ...initialQuestion, roundId: selectedRound.id }));
    }, [initialQuestion, selectedRound]);

    const handleClose = () => setShouldOpenPopup(false);

    const handleChange = (field: string) => (event: React.ChangeEvent<HTMLInputElement | { value: unknown }>) => {
        const value = field === 'sequence' ? Number(event.target.value) : event.target.value;
        setQuestion(prev => ({ ...prev, [field]: value, quizId: quiz.id }));
    };

    const handleOnSubmit = () => {
        handleClose();
        
        let createOrUpdateQuestion: Question = {
            introductionText: question.introductionText,
            questionText: question.questionText,
            sequence: question.sequence,
            roundId: question.roundId,
            id: question.id,
            answers: question.answers,
            attachments: question.attachments,
            maxPoints: question.maxPoints,
            quizId: quiz.id
        }

        if (question.isNewQuestion) {
            createOrUpdateQuestion.id = null;
        }

        onQuestionChange(createOrUpdateQuestion);
    };

    const dialogTitle = question.isNewQuestion
        ? `Een nieuwe vraag aanmaken voor ronde '${selectedRound.name}'`
        : `De vraag '${question.questionText}' aanpassen?`;

    return (
        <Dialog open={shouldOpenPopup} onClose={handleClose} fullWidth maxWidth="sm">
            <DialogTitle>{dialogTitle}</DialogTitle>
            <DialogContent>
                <TextField
                    margin="dense"
                    name="introductionText"
                    label="introductie tekst"
                    type="text"
                    fullWidth
                    value={question.introductionText}
                    onChange={handleChange('introductionText')}
                    InputLabelProps={{ shrink: true }}
                />
                <TextField
                    margin="dense"
                    name="questionText"
                    label="Wat is je vraag?"
                    type="text"
                    fullWidth
                    value={question.questionText}
                    onChange={handleChange('questionText')}
                    InputLabelProps={{ shrink: true }}
                />
                <TextField
                    margin="dense"
                    name="sequence"
                    label="Volgorde"
                    type="number"
                    fullWidth
                    value={question.sequence}
                    onChange={handleChange('sequence')}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleOnSubmit} color="primary">
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default CreateOrEditQuestionPopup;
