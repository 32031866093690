import { LeaderBoardItem } from "../../../../interfaces/Quiz/LeaderBoardItem";
import { Quiz } from "../../../../interfaces/Quiz/Quiz";
import { UNASSIGNED_TEAM_ID } from "../../../Shared/Constants/UnassignedTeamId";

export function calculateTotalPointsOfQuiz(quiz: Quiz) : number {
    return quiz.rounds.reduce((total, round) => {
        return total + round.maxPoints;
    }, 0);
  }

export function calculateLeaderboard(quiz: Quiz): LeaderBoardItem[] {
      const teamScores: { [key: number]: number } = {};
  
      for (const teamRoundPoint of quiz.teamRoundPoints) {
          if (!teamScores[teamRoundPoint.quizTeamId]) {
              teamScores[teamRoundPoint.quizTeamId] = 0;
          }
          teamScores[teamRoundPoint.quizTeamId] += parseFloat(teamRoundPoint.points);
      }
      
      const totalPointsOfQuiz = calculateTotalPointsOfQuiz(quiz)
  
      const leaderboard: LeaderBoardItem[] = [];
  
      for (const team of quiz.quizTeams.filter(quizTeam => quizTeam.id !== UNASSIGNED_TEAM_ID)) {
          const absoluteScore = teamScores[team.id] || 0;
          const relativeScorePercentage = totalPointsOfQuiz > 0 ? (absoluteScore / totalPointsOfQuiz) * 100 : 0;
  
          leaderboard.push({
              rank: 0, 
              teamName: team.name,
              absoluteScore,
              relativeScorePercentage
          });
      }
      leaderboard.sort((a, b) => b.absoluteScore - a.absoluteScore);
  
      leaderboard.forEach((item, index) => {
          item.rank = index + 1;
      });
  
      return leaderboard;
  }