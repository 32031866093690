import { useAuth } from 'react-oidc-context';
import { SaveQuizData } from '../../../interfaces/Quiz/SaveQuizData';
import { QuizRepo } from '../Repo/QuizRepo';
import { useEffect, useState } from 'react';
import SnackbarNotification from '../../Shared/Snackbar/SnackbarNotifcation';
import { CircularProgress } from '@mui/material';
import QuizHeader from './QuizHeader';
import { Quiz } from '../../../interfaces/Quiz/Quiz';
import { ClickAbleTabsOnQuizHeader } from '../Shared/ClickableTabsOnQuizHeader';
import QuizData from './QuizData';
import Rounds from '../../Round/Components/Rounds';
import Scores from '../../Scores/Components/Scores/Scores';
import QuizTeamManager from '../../QuizTeam/Components/QuizTeamManager';
import { SendEmailInvites } from '../../../interfaces/Quiz/SendEmailInvites';
import { UpdateQuizWithSentQuizPlayers, UpdateQuizWithUpdatedPlayerInvitationStatus, UpdateQuizWithUpdatedPlayerUserInSystemStatus } from '../Shared/QuizStateHelper';
import SendEmailQuizPlayersModal from './SendEmailQuizPlayersModal';
import { QuizPlayer } from '../../../interfaces/QuizPlayer/QuizPlayer';
import { isPlayerWithEmail } from '../../../interfaces/QuizPlayer/QuizPlayerHelper';
import { useNavigate, useLocation } from 'react-router-dom';
import QuizPlayersIdsWithEmailKey from '../Interfaces/QuizPlayersIdsWithEmailKey';
import * as signalR from '@microsoft/signalr';

interface EditQuizProps {
    quizId: number
}

const EditQuiz: React.FC<EditQuizProps> = ({ quizId }) => {
    const auth = useAuth();
    const quizRepo = new QuizRepo(auth);
    const navigate = useNavigate();
    const location = useLocation();
    const [activeTab, setActiveTab] = useState<ClickAbleTabsOnQuizHeader>(ClickAbleTabsOnQuizHeader.QUIZMETADATA);
    const [showSnackbar, setShowSnackbar] = useState<boolean>(false);
    const [snackbarMessage, setSnackbarMessage] = useState<string>("");
    const [showInvitationModal, setShowInvitationModal] = useState<boolean>(false);
    const [isQuizLoaded, setIsQuizLoaded] = useState<boolean>(false);
    const [quiz, setQuiz] = useState<Quiz>({} as Quiz);
    const [quizPlayersWithEmail, setQuizPlayersWithEmail] = useState<QuizPlayer[]>([])
    const [allInvitationQuizPlayersChecked, setAllInvitationQuizPlayersChecked] = useState<boolean>(false);

    useEffect(() => {
        const fetchData = async () => {
            await quizRepo.GetQuizAsync((quiz: Quiz) => {
                setIsQuizLoaded(true);
                setQuiz(quiz);
            }, quizId);
        }

        const queryParams = new URLSearchParams(location.search);
        const tabFromQuery = queryParams.get('tab');
        if (tabFromQuery && Object.values(ClickAbleTabsOnQuizHeader).includes(tabFromQuery as ClickAbleTabsOnQuizHeader)) {
            setActiveTab(tabFromQuery as ClickAbleTabsOnQuizHeader);
        }
        else {
            setActiveTab(ClickAbleTabsOnQuizHeader.QUIZMETADATA);
        }
        fetchData().catch(console.error);

        const quizPlayerStatusConnectionToHub = createQuizPlayerStatusConnection();
        const identityServerConnectionToHubFornewUser = createIdentityServerConnection();

        return () => {
            identityServerConnectionToHubFornewUser.stop().then(() => console.log('SignalR Identity Disconnected.'));
            quizPlayerStatusConnectionToHub.stop().then(() => console.log('SignalR Disconnected.'));
        };

    }, [auth, location, quizId]);

    const createIdentityServerConnection = () => {
        const connection = new signalR.HubConnectionBuilder()
            .withUrl(`${process.env.REACT_APP_IdentityAuthority}inviteUserToSystemHub`, {
                accessTokenFactory: () => auth.user?.access_token || '',
            })
            .withAutomaticReconnect()
            .build();

        connection.on('OnInvitePlayerToSystemStatusUpdatedNotication', (message: string) => {
            const [userId, userEmail] = message.split("&");

            setQuiz((prevQuiz: Quiz) => {
                const updatedQuiz = UpdateQuizWithUpdatedPlayerUserInSystemStatus(prevQuiz, userEmail.trim(), userId.trim());
                console.log(updatedQuiz);
                return updatedQuiz;
            });
        });

        connection.start()
            .catch(err => console.error('Connection failed: ', err));

        return connection;
    };

    const createQuizPlayerStatusConnection = () => {
        const connection = new signalR.HubConnectionBuilder()
            .withUrl(`${process.env.REACT_APP_APIBASEURI}quizInvitationStatusHub`, {
                accessTokenFactory: () => auth.user?.access_token || '',
            })
            .withAutomaticReconnect()
            .build();

        connection.on('OnInviteStatusUpdatedNotication', (message: string) => {
            const [uuid, status] = message.split(" - ");
            const emailKeys: QuizPlayersIdsWithEmailKey = { [uuid]: status };

            setQuiz((prevQuiz: Quiz) => {
                const updatedQuiz = UpdateQuizWithUpdatedPlayerInvitationStatus(prevQuiz, emailKeys);
                return updatedQuiz;
            });
        });

        connection.start()
            .catch(err => console.error('Connection failed: ', err));

        return connection;
    };


    const saveQuizData = async (data: SaveQuizData) => {
        await quizRepo.UpdateQuiz((updatedQuiz: Quiz) => {
            setShowSnackbar(true);
            setSnackbarMessage("Het opslaan van de quiz is gelukt");
            setQuiz(updatedQuiz);
        },
            (errorData: Map<string, string[]>) => { console.log("ERROR CONVERER COMES HERE") },
            data.quizId,
            data)
    }

    const setUpdatedQuiz = async(quiz: Quiz) => {
        setQuiz(quiz);
    }

    const updateTabInUrl = (tab: string) => {
        navigate({
            pathname: location.pathname,
            search: `?tab=${tab}`,
        });
    }

    const onQuizMetaDataClick = () => {
        setActiveTab(ClickAbleTabsOnQuizHeader.QUIZMETADATA);
        updateTabInUrl(ClickAbleTabsOnQuizHeader.QUIZMETADATA);
    }

    const onQuizRoundsClick = () => {
        setActiveTab(ClickAbleTabsOnQuizHeader.QUIZROUNDS);
        updateTabInUrl(ClickAbleTabsOnQuizHeader.QUIZROUNDS);
    }

    const onQuizPlayersClick = () => {
        setActiveTab(ClickAbleTabsOnQuizHeader.QUIZTEAMS);
        updateTabInUrl(ClickAbleTabsOnQuizHeader.QUIZTEAMS);
    }

    const onQuizLeaderBoardsClick = () => {
        setActiveTab(ClickAbleTabsOnQuizHeader.QUIZSCORES);
        updateTabInUrl(ClickAbleTabsOnQuizHeader.QUIZSCORES);
    }

    function sendInvitationMail(quizPlayerIds: number[]) {
        setShowInvitationModal(false);

        quizRepo.sendEmailInvites(
            (emailKeys: QuizPlayersIdsWithEmailKey) => {
                setSnackbarMessage("Invitaties zijn succesvol verstuurd");
                setShowSnackbar(true);

                const updatedQuiz = UpdateQuizWithSentQuizPlayers(quiz, emailKeys, quizPlayerIds);
                setUpdatedQuiz(updatedQuiz);
                setQuizPlayersWithEmail([]);
            },
            () => { },
            quiz.id,
            { QuizPlayerIds: quizPlayerIds } as SendEmailInvites
        );
    }

    const handleSendMailsWithQuizPlayers = (quizPlayers: QuizPlayer[]) => {
        setQuizPlayersWithEmail(quizPlayers.filter(isPlayerWithEmail));
        setShowInvitationModal(true);
        setAllInvitationQuizPlayersChecked(true);
    }

    const handleSendMailsClick = () => {
        setQuizPlayersWithEmail(quiz.quizTeams.flatMap(quizTeam => quizTeam.quizPlayers).filter(isPlayerWithEmail));
        setShowInvitationModal(true);
        setAllInvitationQuizPlayersChecked(false);
    };

    const handleCloseInvitationModal = () => {
        setQuizPlayersWithEmail([]);
        setShowInvitationModal(false);
    };

    return (
        <>
            {
                !isQuizLoaded ? (<CircularProgress size={24} />) :
                    (
                        <QuizHeader
                            activeTab={activeTab}
                            onQuizMetaDataClick={onQuizMetaDataClick}
                            onQuizRoundsClick={onQuizRoundsClick}
                            onQuizPlayersClick={onQuizPlayersClick}
                            onQuizLeaderBoardsClick={onQuizLeaderBoardsClick}
                            showQuizMetaDataTab={true}
                            showQuizRoundsTab={true}
                            showQuizTeamMangerTab={true}
                            showQuizScoresTab={true} />

                    )
            }

            {
                !isQuizLoaded ? <></> : (
                    <div id="quiz-tab-content">
                        {activeTab === ClickAbleTabsOnQuizHeader.QUIZMETADATA && <QuizData quiz={quiz} setUpdatedQuiz={setUpdatedQuiz} saveQuizData={saveQuizData} onSendMailsClick={handleSendMailsClick} checkChangedQuizData={true} />}
                        {activeTab === ClickAbleTabsOnQuizHeader.QUIZROUNDS && <Rounds quiz={quiz} setUpdatedQuiz={setUpdatedQuiz} />}
                        {activeTab === ClickAbleTabsOnQuizHeader.QUIZTEAMS && <QuizTeamManager quiz={quiz} setUpdatedQuiz={setUpdatedQuiz} onSendInvitationMailQuizPlayers={handleSendMailsWithQuizPlayers} />}
                        {activeTab === ClickAbleTabsOnQuizHeader.QUIZSCORES && <Scores quiz={quiz} setUpdatedQuiz={setUpdatedQuiz} />}
                    </div>
                )
            }

            <SnackbarNotification
                textToDisplay={snackbarMessage}
                setShouldOpenSnackbar={setShowSnackbar}
                shouldOpenSnackbar={showSnackbar}
                horizontalLocation='center'
                verticalLocation='top'
                severity='success'
            />

            <SendEmailQuizPlayersModal
                open={showInvitationModal} onClose={handleCloseInvitationModal}
                allInvitationQuizPlayersChecked={allInvitationQuizPlayersChecked}
                quizPlayers={quizPlayersWithEmail}
                handleSendMail={sendInvitationMail} />
        </>
    );
};

export default EditQuiz;