import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton } from "@mui/material";
import { QuizPlayerCardProps } from "../Props/QuizPlayerCardProps";
import { getPlayerRegisterdClass, getPlayerStatusClass } from "../Shared/QuizPlayerHelper";
import SendIcon from "@mui/icons-material/Send";
import { isPlayerWithEmail } from "../../../interfaces/QuizPlayer/QuizPlayerHelper";

export const NonDraggableQuizPlayerCard: React.FC<QuizPlayerCardProps> = ({ quizPlayer, onPlayerDelete, onSendInvitationEmailQuizPlayer}) => {

  function handleOnDeleteButtonClick() {
    onPlayerDelete(quizPlayer);
  }

  function handleInvitationMailQuizPlayerButtonClick() {
    onSendInvitationEmailQuizPlayer(quizPlayer)
  }

  const className = `quiz-player-card ${getPlayerRegisterdClass(quizPlayer)} ${getPlayerStatusClass(quizPlayer)}`;

  return (
    <div className={className}>
      <div className="quiz-player-avatar">
        {quizPlayer.initials}
      </div>

      <span className="quiz-player-name">
        {quizPlayer.quizPlayerName}
      </span>

      <div className="quiz-player-hover-controls">
      {
            isPlayerWithEmail(quizPlayer) && (
            <IconButton aria-label="Send" onClick={handleInvitationMailQuizPlayerButtonClick}>
              <SendIcon />
            </IconButton>
            )
          }
        <IconButton aria-label="Delete" onClick={handleOnDeleteButtonClick}>
          <DeleteIcon />
        </IconButton>
      </div>
    </div>
  );
};
