import { CreateQuizPlayer } from "../../../interfaces/QuizPlayer/CreateQuizPlayer";
import { QuizPlayer } from "../../../interfaces/QuizPlayer/QuizPlayer";
import { UpdateQuizPlayer } from "../../../interfaces/QuizPlayer/UpdateQuizPlayer";
import { getApiClient } from "../../Shared/ApiClient/AxiosClient";
import { AxiosInstance } from "axios";
import { AuthContextProps } from "react-oidc-context";

export class QuizPlayerRepo {
  private readonly apiClient: AxiosInstance;

  constructor(auth: AuthContextProps) {
    this.apiClient = getApiClient(auth);
  }

  async RemoveQuizPlayer(callback: () => void, quizId: number, quizPlayerId: number) {
    await this.apiClient.delete(`/quiz/${quizId}/Player/${quizPlayerId}`)
      .then((res: any) => {
        callback();
      });
  }

  async MoveQuizPlayerToQuizTeam(callback: () => void, quizId: number, quizPlayerId: number, quizTeamId: number) {
    await this.apiClient.put(`/quiz/${quizId}/Player/${quizPlayerId}/MoveToQuizTeam/${quizTeamId}`)
      .then((res: any) => {
        callback();
      });
  }

  async MoveQuizPlayerToUnassigned(callback: () => void, quizId: number, quizPlayerId: number) {
    await this.apiClient.put(`/quiz/${quizId}/Player/${quizPlayerId}/MoveToUnassigned`)
      .then((res: any) => {
        callback();
      });
  }

  async CreateNewQuizPlayer(callback: (quizPlayer: QuizPlayer) => void, quizId: number, data: CreateQuizPlayer) {
    await this.apiClient.post(`/quiz/${quizId}/Player`, data)
      .then((res: any) => {
        callback(res.data as QuizPlayer);
      });
  }

  async EditNewQuizPlayer(callback: () => void, quizId: number, quizPlayerId: number, data: UpdateQuizPlayer) {
    await this.apiClient.put(`/quiz/${quizId}/Player/${quizPlayerId}`, data)
      .then((res: any) => {
        callback();
      });
  }

  async GetQuizPlayerByEmail(callback: (quizPlayer: QuizPlayer) => void, quizId: number, email: string) {
    await this.apiClient.get(`/quiz/${quizId}/Player/${email}/Email`)
      .then((res: any) => {
        callback(res.data as QuizPlayer);
      });
  }
}
