import { AxiosInstance } from "axios";

import { getUnauthorizedApiClient } from "../../Shared/ApiClient/AxiosClient";

export class MailRepo {
    private readonly apiClient:AxiosInstance;
    
    constructor() {
      this.apiClient = getUnauthorizedApiClient();
    }
  
    async IsKeyValid(callback: any, key: string): Promise<void> {
      const res = await this.apiClient.get(`/mail/${key}/isvalid`);

      if (typeof res.data === "boolean") 
      {
          callback(res.data, "");
      }
      else 
      {
          const error = res.data.errors[0]?.error || "Onbekende error";
          callback(false, error);
      } 
    }

    async InvitationToQuiz(callback: any, key: string, hasAccepted: boolean): Promise<void> {
      await this.apiClient.put(`/mail/${key}/InvitationToQuiz?hasAccepted=${hasAccepted}`).then((res: any) => {
        callback();
      })
    }

    async RegisterToSystemWithQuiz(callback: any, key: string, userId: string): Promise<void> {
      await this.apiClient.put(`/mail/${key}/RegisterToSystemWithQuiz/${userId}`).then((res: any) => {
        callback();
      })
    }
}  