import { useDraggable } from "@dnd-kit/core";
import { useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton } from "@mui/material";
import { QuizPlayerCardProps } from "../Props/QuizPlayerCardProps";
import { getPlayerRegisterdClass, getPlayerStatusClass } from "../Shared/QuizPlayerHelper";
import SendIcon from "@mui/icons-material/Send";
import { isPlayerWithEmail } from "../../../interfaces/QuizPlayer/QuizPlayerHelper";

export const DraggableQuizPlayerCard: React.FC<QuizPlayerCardProps> = ({ quizPlayer, onPlayerDelete, onSendInvitationEmailQuizPlayer }) => {
  const [isHovered, setIsHovered] = useState(false);

  const { attributes, listeners, setNodeRef, transform, isDragging } = useDraggable({
    id: quizPlayer.id,
    data: { quizPlayer },
    disabled: isHovered
  });

  function handleOnDeleteButtonClick() {
    onPlayerDelete(quizPlayer);
  }

  function handleInvitationMailQuizPlayerButtonClick() {
    onSendInvitationEmailQuizPlayer(quizPlayer)
  }

  const style = {
    transform: transform ? `translate3d(${transform.x}px, ${transform.y}px, 0)` : undefined,
    cursor: isDragging ? "grabbing" : "grab",
  };

  const className = `quiz-player-card ${getPlayerRegisterdClass(quizPlayer)} ${getPlayerStatusClass(quizPlayer)}`;

  return (
    <div
      ref={setNodeRef}
      className={className}
      style={style}
      {...attributes}
      {...listeners}
    >
      <div className="quiz-player-avatar">
        {quizPlayer.initials}
      </div>

      <span className="quiz-player-name">
        {quizPlayer.quizPlayerName}
      </span>

      {!isDragging && (
        <div
          className="quiz-player-hover-controls"
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          {
            isPlayerWithEmail(quizPlayer) && (
            <IconButton aria-label="Send" onClick={handleInvitationMailQuizPlayerButtonClick}>
              <SendIcon />
            </IconButton>
            )
          }
          <IconButton aria-label="Delete" onClick={handleOnDeleteButtonClick}>
            <DeleteIcon />
          </IconButton>
        </div>
      )}
    </div>
  );
};
