import React, { useEffect, useState } from 'react';
import { AlertColor } from '@mui/material';
import { QuizTeamWithQuizPlayers } from '../../../interfaces/QuizTeam/QuizTeamWithQuizPlayers';
import { QuizPlayer } from '../../../interfaces/QuizPlayer/QuizPlayer';
import { CreateQuizTeam as createQuizTeam } from '../../../interfaces/QuizTeam/CreateQuizTeam';
import { CreateQuizPlayer } from '../../../interfaces/QuizPlayer/CreateQuizPlayer';
import { QuizPlayerKind } from '../../../interfaces/QuizPlayer/QuizPlayerKind';
import { useAuth } from 'react-oidc-context';
import { QuizTeamRepo } from '../Repo/QuizTeamRepo';
import { QuizPlayerRepo } from '../../QuizPlayer/Repo/QuizPlayerRepo';
import { QuizTeam } from '../../../interfaces/QuizTeam/QuizTeam';
import SnackbarNotification from '../../Shared/Snackbar/SnackbarNotifcation';
import { SnackbarSeverityEnum } from '../../Shared/Snackbar/SnackbarSeverityEnum';
import { Quiz } from '../../../interfaces/Quiz/Quiz';
import { updateQuizRemoveQuizPlayerFromQuiz, updateQuizSetQuizPlayerToUnattached, updateQuizWihtChangedQuizTeam, updateQuizWithAddedPlayerToUnassignedTeam, updateQuizWithADeletedQuizTeam, updateQuizWithNewlyAddedQuizUsersToUnassignedTeam, updateQuizWithNewQuizPlayers, updateQuizWithNewQuizTeam, updateQuizWithNewRandomizedQuizTeams, updateQuizWithQuizTeamAndUnassingedQuizPlayer, updateQuizWithRemovedExternalPlayerFromUnassingedTeam, updateQuizWithRemovedQuizPlayerFromUnAssingedQuizTeam } from "../Shared/QuizTeamStateHelper"
import { UNASSIGNED_TEAM_ID } from '../../Shared/Constants/UnassignedTeamId';
import '../Styles/QuizTeamManager.scss'
import QuizTeamManagerFullScreen from './QuizTeamManagerFullScreen';
import QuizTeamManagerMobile from './QuizTeamManagerMobile';
import { useMediaQuery } from 'react-responsive';
import { QuizTeamManagerProps } from '../Props/QuizTeamManagerProps';
import { playerQuizLocation } from '../Enum/playerQuizLocation';
import { CustomError } from '../../../interfaces/Error/CustomError';
import ErrorSnackbar from '../../Shared/Errors/ErrorSnackbar';
import { GetNewGuestQuizTeam } from '../Shared/QuizTeamManagerHelper';
import { createRandomizedQuizTeams } from '../Shared/QuizTeamRandomizerHelper';
import { UpdateQuizTeam } from '../../../interfaces/QuizTeam/UpdateQuizTeam';
import { QuizPlayerUser } from '../../../interfaces/QuizPlayer/QuizPlayerUser';
import { UpdateQuizPlayer } from '../../../interfaces/QuizPlayer/UpdateQuizPlayer';

interface QuizDataProps {
  quiz: Quiz;
  setUpdatedQuiz: (quiz: Quiz) => void;
  onSendInvitationMailQuizPlayers: (quizPlayers: QuizPlayer[]) => void;
}

const QuizTeamManager: React.FC<QuizDataProps> = ({ quiz, setUpdatedQuiz, onSendInvitationMailQuizPlayers  }) => {
  const [showSnackbar, setShowSnackbar] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<AlertColor>(SnackbarSeverityEnum.Warning);

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
  const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1224px)' })

  const auth = useAuth();
  const quizTeamRepo = new QuizTeamRepo(auth);
  const quizPlayerRepo = new QuizPlayerRepo(auth);
  const [errors, setErrors] = useState<CustomError[]>([]);


  useEffect(() => {
  }, [quiz.id, auth]);


  const updateQuizTeams = (
    prevQuizTeams: QuizTeamWithQuizPlayers[],
    fromQuizTeamId: number,
    toQuizTeamId: number,
    quizPlayerId: number
  ) => {
    const fromQuizTeamIndex = prevQuizTeams.findIndex((quizTeam) => quizTeam.id == fromQuizTeamId);
    const toQuizTeamIndex = prevQuizTeams.findIndex((quizTeam) => quizTeam.id == toQuizTeamId);
    const quizPlayerIndex = prevQuizTeams[fromQuizTeamIndex].quizPlayers.findIndex((quizPlayer) => quizPlayer.id == quizPlayerId);
    const movedQuizPlayer = prevQuizTeams[fromQuizTeamIndex].quizPlayers[quizPlayerIndex];

    let updatedQuiz = updateQuizWithNewQuizPlayers(quiz, prevQuizTeams, fromQuizTeamIndex, toQuizTeamIndex, quizPlayerId, movedQuizPlayer, quizPlayerIndex);
    setUpdatedQuiz(updatedQuiz);
  };

  //TODO move to statehelper
  function moveQuizPlayerToTeam(quizPlayerId: number, fromQuizTeamId: number, toQuizTeamId: number) {
    if (toQuizTeamId == UNASSIGNED_TEAM_ID) {
      quizPlayerRepo.MoveQuizPlayerToUnassigned(() => {
        updateQuizTeams(quiz.quizTeams, fromQuizTeamId, toQuizTeamId, quizPlayerId)
      }, quiz.id, quizPlayerId);
    } else {
      quizPlayerRepo.MoveQuizPlayerToQuizTeam(() => {
        updateQuizTeams(quiz.quizTeams, fromQuizTeamId, toQuizTeamId, quizPlayerId)
      }, quiz.id, quizPlayerId, toQuizTeamId);
    }
  }

  function GetQuizPlayer(playerQuizId: number, quizTeamId: number): QuizPlayer {
    let foundQuizPlayer = quiz.quizTeams.find(x => x.id === quizTeamId)
      .quizPlayers.find(x => x.id === playerQuizId);

    return foundQuizPlayer;
  }

  const onPlayerDeleteInAssignedTeam = (quizPlayerId: number, quizTeamId: number) => {
    onDeleteQuizPlayer(quizPlayerId, quizTeamId);
  }

  const onPlayerDeleteInUnAssignedTeam = (quizPlayerId: number, quizTeamId: number) => {
    onDeleteQuizPlayer(quizPlayerId, quizTeamId);
  }

  function onDeleteQuizPlayer(quizPlayerId: number, quizTeamId: number) {
    const foundQuizPlayer = GetQuizPlayer(quizPlayerId, quizTeamId);

    if (isQuizPlayerInternal(foundQuizPlayer)) {
      setQuizPlayerUserToUnattached(quizTeamId, foundQuizPlayer);
    }
    else if(isQuizPlayerGuest(foundQuizPlayer))
    {
      removeQuizPlayerFromQuiz(quizTeamId, quizPlayerId);
    }
    else 
    {
      setInvitationQuizPlayerUnattached(quizTeamId, foundQuizPlayer)
    }
  }

  function setInvitationQuizPlayerUnattached(quizTeamId: number,quizPlayer: QuizPlayer){
    quizPlayerRepo.EditNewQuizPlayer(() => {
      let updatedQuiz = updateQuizRemoveQuizPlayerFromQuiz(quiz, quizTeamId, quizPlayer.id);
      setUpdatedQuiz(updatedQuiz);
    },
    quiz.id,
    quizPlayer.id,
    { 
      isAttachedToQuiz: false,
      isInvited: quizPlayer.isInvited,
      status: quizPlayer.quizPlayerStatus,
      userId: null,
      quizTeamId: null
    } as UpdateQuizPlayer)
  }

  function isQuizPlayerGuest(quizPlayer: QuizPlayer): boolean {
    return Boolean(quizPlayer.guestName && quizPlayer.guestName.trim());
}

function isQuizPlayerInternal(quizPlayer: QuizPlayer): boolean {
    return Boolean(quizPlayer.userId);
}


  function removeQuizPlayerFromQuiz(quizTeamId: number, quizPlayerId: number) {
    quizPlayerRepo.RemoveQuizPlayer(() => {
      let updatedQuiz = updateQuizRemoveQuizPlayerFromQuiz(quiz, quizTeamId, quizPlayerId);
      setUpdatedQuiz(updatedQuiz);
    }, quiz.id, quizPlayerId);
  }

  function setQuizPlayerUserToUnattached(quizTeamId: number, quizPlayer: QuizPlayer) {
    quizPlayerRepo.EditNewQuizPlayer(() => {
      let updatedQuiz = updateQuizSetQuizPlayerToUnattached(quiz, quizTeamId, quizPlayer.id);
      setUpdatedQuiz(updatedQuiz);
    },
    quiz.id,
    quizPlayer.id,
    { 
      isAttachedToQuiz: false,
      isInvited: quizPlayer.isInvited,
      status: quizPlayer.quizPlayerStatus,
      userId: quizPlayer.userId,
      quizTeamId: null
    } as UpdateQuizPlayer)
  }

  async function checkIfPlayerIsInQuiz(email: string): Promise<{
    found: boolean;
    location: playerQuizLocation | null;
    team?: QuizTeamWithQuizPlayers;
    quizPlayer?: QuizPlayer;
  }> {
    for (const team of quiz.quizTeams) {
      const quizPlayer = team.quizPlayers.find(
        quizPlayer =>
          (quizPlayer.invitationMail &&
            quizPlayer.invitationMail.toUpperCase() === email.toUpperCase()) ||
          (quizPlayer.userEmail &&
            quizPlayer.userEmail.toUpperCase() === email.toUpperCase())
      );
      if (quizPlayer) {
        return { found: true, location: playerQuizLocation.Team, team, quizPlayer };
      }
    }

    const unattachedPlayer = quiz.quizUsersNotAttachedToQuiz.find(
      quizPlayerUser => quizPlayerUser.user.email.toUpperCase() === email.toUpperCase()
    );

    if (unattachedPlayer) {
      return {
        found: true,
        location: playerQuizLocation.Unattached,
        quizPlayer: unattachedPlayer.quizPlayer,
      };
    }
  
    return new Promise<{
      found: boolean;
      location: playerQuizLocation | null;
      team?: QuizTeamWithQuizPlayers;
      quizPlayer?: QuizPlayer;
    }>(resolve => {
      quizPlayerRepo.GetQuizPlayerByEmail(
        (quizPlayer: QuizPlayer) => {
          if (!quizPlayer) {
            resolve({ found: false, location: null });
          } else {
            resolve({
              found: true,
              location: playerQuizLocation.UnattachedAndUnregistered,
              quizPlayer,
            });
          }
        },
        quiz.id,
        email
      );
    });
  }
  
  function handleAddGuestQuizTeamClick(guestQuizTeamName: string){
    return quizTeamRepo.CreateQuizTeam((quizTeam: QuizTeam) => {
      
      const newGuestQuizTeam = GetNewGuestQuizTeam(quizTeam.id, guestQuizTeamName, []);
      let updatedQuiz = updateQuizWithNewQuizTeam(quiz, newGuestQuizTeam);
      setUpdatedQuiz(updatedQuiz);
    },
      quiz.id,
      {
        QuizTeamName: guestQuizTeamName,
        Sequence: 0
      } as createQuizTeam);
  }
  async function handleRandomizeQuizTeams(quizTeams: QuizTeamWithQuizPlayers[]): Promise<void> {
    const newQuizTeams = await createRandomizedQuizTeams(quiz.id, quizTeams, quizTeamRepo);

    let updatedQuiz = updateQuizWithNewRandomizedQuizTeams(quiz, newQuizTeams);
    setUpdatedQuiz(updatedQuiz);
  }
  

  function createQuizPlayer(createQuizPlayer: CreateQuizPlayer) {
    return quizPlayerRepo.CreateNewQuizPlayer((quizPlayer: QuizPlayer) => {
      const teamIndex = quiz.quizTeams.findIndex(team => team.id === UNASSIGNED_TEAM_ID);
      let updatedQuiz = updateQuizWithAddedPlayerToUnassignedTeam(quiz, teamIndex, quizPlayer);
      setUpdatedQuiz(updatedQuiz);
    },
      quiz.id,
      createQuizPlayer);
  }

  async function handleAddQuizPlayerUsersClick(selectedQuizUsers: QuizPlayerUser[]): Promise<void> {
    const selectedExistingPlayerIds = selectedQuizUsers.map(quizUser => quizUser.user.id);
    const remainingquizUsersThatArentAttachedToQuiz = quiz.quizUsersNotAttachedToQuiz.filter(
        quizPlayerUser => !selectedExistingPlayerIds.includes(quizPlayerUser.user.id)
    );

    let newQuizPlayers: QuizPlayer[] = [];

    const createQuizPlayerPromises = selectedQuizUsers.map(async (quizPlayerUser) => {
        return quizPlayerUser.quizPlayer
            ? updateExistingQuizPlayer(quizPlayerUser, quiz.id, newQuizPlayers)
            : createNewQuizPlayer(quizPlayerUser, quiz.id, newQuizPlayers);
    });

    await Promise.all(createQuizPlayerPromises);

    const teamIndex = quiz.quizTeams.findIndex(team => team.id === UNASSIGNED_TEAM_ID);
    let updatedQuiz = updateQuizWithNewlyAddedQuizUsersToUnassignedTeam(
        quiz,
        teamIndex,
        newQuizPlayers,
        remainingquizUsersThatArentAttachedToQuiz
    );
    setUpdatedQuiz(updatedQuiz);
}

function updateExistingQuizPlayer(quizPlayerUser: QuizPlayerUser, quizId: number, newQuizPlayers: QuizPlayer[]): Promise<QuizPlayer> {
  return new Promise<QuizPlayer>((resolve) => {
      const updateQuizPlayer = {
          userId: quizPlayerUser.quizPlayer!.userId,
          status: quizPlayerUser.quizPlayer!.quizPlayerStatus,
          isInvited: quizPlayerUser.quizPlayer!.isInvited,
          isAttachedToQuiz: true,
          quizTeamId: null
      } as UpdateQuizPlayer;

      quizPlayerRepo.EditNewQuizPlayer(
          () => {
              newQuizPlayers.push(quizPlayerUser.quizPlayer!);
              resolve(quizPlayerUser.quizPlayer!);
          },
          quizId,
          quizPlayerUser.quizPlayer!.id,
          updateQuizPlayer
      );
  });
}

function createNewQuizPlayer(quizPlayerUser: QuizPlayerUser, quizId: number, newQuizPlayers: QuizPlayer[]): Promise<QuizPlayer> {
  return new Promise<QuizPlayer>((resolve) => {
      const createQuizPlayer = {
          userId: quizPlayerUser.user.id,
          quizPlayerKind: QuizPlayerKind.EXISTING
      } as CreateQuizPlayer;

      quizPlayerRepo.CreateNewQuizPlayer((quizPlayer: QuizPlayer) => {
          newQuizPlayers.push(quizPlayer);
          resolve(quizPlayer);
      }, quizId, createQuizPlayer);
  });
}


function SetSnackbarOptions(message: string, severity: SnackbarSeverityEnum, showSnackbar: boolean){
  setSnackbarMessage(message);
  setSnackbarSeverity(severity);
  setShowSnackbar(showSnackbar);
}

  const  handleAddQuizPlayerWithEmail = async (email: string) => {
    var quizPlayerInfo = await checkIfPlayerIsInQuiz(email);
    if (quizPlayerInfo.found) {
      if (quizPlayerInfo.location === playerQuizLocation.Team) {
        SetSnackbarOptions("Speler is al toegevoegd aan de quiz.", SnackbarSeverityEnum.Error, true);
      }
      else if (quizPlayerInfo.location === playerQuizLocation.Unattached) {
        let quizPlayerUser = quiz.quizUsersNotAttachedToQuiz.find(quizPlayerUser => quizPlayerUser.user.email.toUpperCase() == email.toUpperCase());

        createQuizPlayer({ userId: quizPlayerUser.user.id, quizPlayerKind: QuizPlayerKind.EXISTING } as CreateQuizPlayer)
        SetSnackbarOptions("Speler bestaat al en werd toegevoegd aan de niet toegewezen lijst.\n(Geen Mail verstuurd)", SnackbarSeverityEnum.Warning, true);
      }
      else if(quizPlayerInfo.location === playerQuizLocation.UnattachedAndUnregistered){
        quizPlayerRepo.EditNewQuizPlayer(() => {
          const teamIndex = quiz.quizTeams.findIndex(team => team.id === UNASSIGNED_TEAM_ID);
          let updatedQuiz = updateQuizWithAddedPlayerToUnassignedTeam(quiz, teamIndex, quizPlayerInfo.quizPlayer);
          setUpdatedQuiz(updatedQuiz);
        }, quiz.id, quizPlayerInfo.quizPlayer.id,
           {
             userId: null,
             status: quizPlayerInfo.quizPlayer.quizPlayerStatus,
             quizTeamId: null,
             isAttachedToQuiz: true,
             isInvited: quizPlayerInfo.quizPlayer.isInvited
           } as UpdateQuizPlayer)
      }
    }
    else {
      createQuizPlayer({ invitationMail: email, quizPlayerKind: QuizPlayerKind.INVITATION } as CreateQuizPlayer)
      SetSnackbarOptions(`Er is een mail verstuurd naar ${email}.`, SnackbarSeverityEnum.Success, true);
    }
  }

  function handleAddGuestQuizPlayer(guestName: string) {
    quizPlayerRepo.CreateNewQuizPlayer(
      (quizPlayer: QuizPlayer) => {
      const teamIndex = quiz.quizTeams.findIndex(team => team.id === UNASSIGNED_TEAM_ID);
      let updatedQuiz = updateQuizWithAddedPlayerToUnassignedTeam(quiz, teamIndex, quizPlayer);
      setUpdatedQuiz(updatedQuiz);
    }, quiz.id,
      {
        guestName: guestName,
        quizPlayerKind: QuizPlayerKind.GUEST
      } as CreateQuizPlayer)
      .then(() => {
        setErrors([]);
      })
      .catch(error => {
          setErrors(error.response.data.errors);
      })
  }

  const handleQuizTeamDelete = (quizTeamToDelete: QuizTeamWithQuizPlayers) => {
    if (quizTeamToDelete.id === UNASSIGNED_TEAM_ID) {
      return;
    }

    return quizTeamRepo.RemoveQuizTeam(() => {
      let updatedQuiz = updateQuizWithADeletedQuizTeam(quiz, quizTeamToDelete);
      setUpdatedQuiz(updatedQuiz);
    }, quiz.id, quizTeamToDelete.id)
  }

const handleSendInvitationMailQuizPlayers = (quizPlayers: QuizPlayer[]) => {
  onSendInvitationMailQuizPlayers(quizPlayers);
}

const handleQuizTeamChange = (quizTeam: QuizTeam) => {
  quizTeamRepo.UpdateQuizTeam(() => {
    let updatedQuiz = updateQuizWihtChangedQuizTeam(quiz, quizTeam);
    setUpdatedQuiz(updatedQuiz);
  }, quiz.id, 
    quizTeam.id, {
      QuizTeamName: quizTeam.name
    } as UpdateQuizTeam)
}

const sharedQuizTeamManagerProps = {
  quizPlayerUsersNotAttachedToQuiz: quiz.quizUsersNotAttachedToQuiz,
  quizTeams: quiz.quizTeams,

  onAddClickQuizPlayerGuest: handleAddGuestQuizPlayer,
  onAddClickQuizPlayerUserMultiSelect: handleAddQuizPlayerUsersClick,
  onAddClickQuizPlayerWithEmail: handleAddQuizPlayerWithEmail,

  onRandomizeQuizTeams: handleRandomizeQuizTeams,
  onAddGuestQuizTeam: handleAddGuestQuizTeamClick,
  onDeleteQuizTeam: handleQuizTeamDelete,
  moveQuizPlayerToTeam: moveQuizPlayerToTeam,
  onQuizPlayerDeleteInAssignedTeam: onPlayerDeleteInAssignedTeam,
  onQuizPlayerDeleteInUnassignedTeam: onPlayerDeleteInUnAssignedTeam,
  onSendInvitationMailQuizPlayers: handleSendInvitationMailQuizPlayers,
  onQuizTeamChange: handleQuizTeamChange
 
} as QuizTeamManagerProps;

  return (
    <>
      {isDesktopOrLaptop && <QuizTeamManagerFullScreen {...sharedQuizTeamManagerProps} />}
      {isTabletOrMobile && <QuizTeamManagerMobile {...sharedQuizTeamManagerProps} />}

      {errors && <ErrorSnackbar errors={errors}/>}

      <SnackbarNotification
        textToDisplay={snackbarMessage}
        setShouldOpenSnackbar={setShowSnackbar}
        shouldOpenSnackbar={showSnackbar}
        horizontalLocation='center'
        verticalLocation='top'
        severity={snackbarSeverity}
      >
      </SnackbarNotification>
    </>
  );
};

export default QuizTeamManager;