import RoundList from './RoundList';
import RoundPopup from './RoundPopup';
import QuestionListDesktop from '../../Question/Components/QuestionList/QuestionListDesktop';
import { RoundsDesktopScreenProps } from '../Properties/RoundsDesktopScreenProps';
import { useState } from 'react';
import { QuestionFromRound } from '../../../interfaces/Quiz/QuestionFromRound';
import QuestionSummaryList from '../../Question/Components/QuestionList/QuestionListSummary';
import { Round } from '../../../interfaces/Quiz/Round';

const Rounds: React.FC<RoundsDesktopScreenProps> = ({ quiz, selectedRound,
    defaultNewRound, handelOnDeleteRound, handleRoundClick, onAddRoundClick,
    handleOnReorderRounds, onQuizChange, onRoundChange, saveNewRound, setShouldOpenPopup,
    shouldOpenPopup }) => {
        const [activeQuestion, setActiveQuestion] = useState<QuestionFromRound>();
        const [scrollToQuestion, setScrollToQuestion]= useState<QuestionFromRound>();

        const handleSelectRound = (round: Round) => {
            const activeQuestion = round.questionsFromRound
                .filter(question => question.sequence)
                .sort((a, b) => a.sequence - b.sequence)[0];

            setActiveQuestion(activeQuestion);
            setScrollToQuestion(activeQuestion);

            handleRoundClick(round);
        }

        const handleQuestionSelectInSummary = (question: QuestionFromRound) => {
            setScrollToQuestion(question);
            setActiveQuestion(question);
          };

        const handleActiveQuestionSelectInQuestionList = (question: QuestionFromRound) => {
            setScrollToQuestion(null);
            setActiveQuestion(question);
        }
          

    return (
        <>
            <div id="round-container">
                <div id="round-list-container">
                    <div className="round-column-content">
                        <RoundList
                            quiz={quiz}
                            selectedRound={selectedRound}
                            onRoundClick={handleSelectRound}
                            onAddRound={onAddRoundClick}
                            onReorderRounds={handleOnReorderRounds}
                            onDeleteRound={handelOnDeleteRound}
                            onRoundChange={onRoundChange} />
                    </div>
                </div>
                <div id="question-summary-list-container">
                    <QuestionSummaryList ActiveQuestion={activeQuestion} 
                                        HandleQuestionSelect={handleQuestionSelectInSummary} 
                                        Round={selectedRound}/>
                </div>
                <div id="question-list-container">
                    <div className="question-column-content">
                        <QuestionListDesktop
                            selectedRound={selectedRound}
                            quiz={quiz}
                            onQuizChange={onQuizChange}
                            scrollToQuestion={scrollToQuestion}
                            HandleQuestionSelect={handleActiveQuestionSelectInQuestionList}
                        />
                    </div>
                </div>
            </div>

            <RoundPopup
                roundDataProp={defaultNewRound()}
                onRoundPopupDataChange={saveNewRound}
                shouldOpenPopup={shouldOpenPopup}
                setShouldOpenPopup={setShouldOpenPopup}></RoundPopup>
        </>
    );
}

export default Rounds;