import { FC, useEffect, useState } from 'react';
import { SaveQuizData } from '../../../interfaces/Quiz/SaveQuizData';
import { QuizRepo } from '../Repo/QuizRepo';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'react-oidc-context';
import QuizHeader from './QuizHeader';
import { ClickAbleTabsOnQuizHeader } from '../Shared/ClickableTabsOnQuizHeader';
import QuizData from './QuizData';
import { Quiz } from '../../../interfaces/Quiz/Quiz';
import { Address } from '../../Shared/Components/Address/Interface/Address';

const AddQuiz: FC = () => {
    const [activeTab, setActiveTab] = useState<string>(ClickAbleTabsOnQuizHeader.QUIZMETADATA);

    const navigate = useNavigate();
    const auth = useAuth();
    const quizRepo = new QuizRepo(auth);

    useEffect(() => {

    }, [auth])

    const saveQuizData = async (data: SaveQuizData) => {
        data.quizOwnerEmail = "Invaild.Email@gmail.com";// FCO => Get user from auth
        
        await quizRepo.createQuizAsync((quizId: number) => {
            navigate(`/quiz/edit/${quizId}`)
        }
            , (errorData: Map<string, string[]>) => { console.log("ERROR CONVERER COMES HERE") },
            data);
    }

    const defaultAddress = (): Address => {
        return {
            country: "",   
            countryISO: "",
            province: "",
            town: "",
            postalcode: "",
            street: "",
            number: "",
            latitude: null,
            longitude: null
          } as Address
    }

    const defaultEmptyQuiz = (): Quiz => {
        return {
            isPublished: false,
            isQuizOwner: false,
            name: '',
            ownerName: '',
            startDate: null,
            publishDate : null,
            id: null,
            quizTeams: [],
            rounds: [],
            teamRoundPoints : [],
            quizUsersNotAttachedToQuiz : [],
            address: defaultAddress()
        }
    }    

    const onQuizMetaDataClick = () => {
        setActiveTab(ClickAbleTabsOnQuizHeader.QUIZMETADATA)
    }

    return (
        <>
            <QuizHeader
                 activeTab={ClickAbleTabsOnQuizHeader.QUIZMETADATA}
                 onQuizMetaDataClick={onQuizMetaDataClick}
                 onQuizRoundsClick={() => {}}
                 onQuizPlayersClick={() => {}}
                 onQuizLeaderBoardsClick={() => {}}
                 showQuizMetaDataTab={true}
                 showQuizRoundsTab={false}
                 showQuizTeamMangerTab={false}
                 showQuizScoresTab={false}/>
            
            <div id="quiz-tab-content">
                {activeTab === ClickAbleTabsOnQuizHeader.QUIZMETADATA && <QuizData quiz={defaultEmptyQuiz()} setUpdatedQuiz={null} onSendMailsClick={null} saveQuizData={saveQuizData} checkChangedQuizData={false}/>}
            </div>
        </>
    );
};

export default AddQuiz;