import { QuestionFromRound } from "../../../interfaces/Quiz/QuestionFromRound";

export function DoesQuestionHasAnswersFilledIn(question: QuestionFromRound){
    if(question.answers.length === 0) return false;

    if(question.answers.filter(answer => answer.description.trim() == "").length > 0) return false;

    return true;
}

export function QuestionCompleted(question: QuestionFromRound){
    if(question.questionText.trim() === "") return false;

    return DoesQuestionHasAnswersFilledIn(question);
}