import { Quiz } from "../../../interfaces/Quiz/Quiz";
import { QuizTableRow } from "../../../interfaces/Quiz/QuizTableRow";
import { QuizPlayerStatusses } from "../../../interfaces/QuizPlayer/QuizPlayerStatus";
import QuizPlayersIdsWithEmailKey from "../Interfaces/QuizPlayersIdsWithEmailKey";


export function UpdateQuizWithUpdatedPlayerInvitationStatus(quiz: Quiz, emailKeys: QuizPlayersIdsWithEmailKey): Quiz {
    const updatedQuiz = {
        ...quiz,
        quizTeams: quiz.quizTeams.map(team => ({
            ...team,
            quizPlayers: team.quizPlayers.map(quizPlayer => {
                const emailKey = emailKeys[quizPlayer.id];
                if (emailKey !== undefined) {
                    return { 
                        ...quizPlayer, 
                        isInvited: true, 
                        quizPlayerStatus: QuizPlayerStatusses[emailKey as keyof typeof QuizPlayerStatusses],
                        quizInvitationKey: emailKey
                    };
                }
                return quizPlayer;
            })
        }))
    } as Quiz;
    return updatedQuiz;
}

export function UpdateQuizWithUpdatedPlayerUserInSystemStatus(quiz: Quiz, email: string, userId: string): Quiz {
    const updatedQuiz = {
        ...quiz,
        quizTeams: quiz.quizTeams.map(team => ({
            ...team,
            quizPlayers: team.quizPlayers.map(quizPlayer => {
                if (quizPlayer.invitationMail !== null && quizPlayer.invitationMail.trim() === email) {
                    return { 
                        ...quizPlayer, 
                        isInvited: true,
                        userEmail: email,
                        userId: userId
                    };
                }
                return quizPlayer;
            })
        }))
    } as Quiz;
    return updatedQuiz;
}

export function UpdateQuizWithSentQuizPlayers(quiz: Quiz, emailKeys: QuizPlayersIdsWithEmailKey, quizPlayerIds: number[]): Quiz {
    const updatedQuiz = {
        ...quiz,
        quizTeams: quiz.quizTeams.map(team => ({
            ...team,
            quizPlayers: team.quizPlayers.map(quizPlayer =>
                quizPlayerIds.includes(quizPlayer.id)
                    ? { 
                        ...quizPlayer, 
                        isInvited: true, 
                        quizPlayerStatus: QuizPlayerStatusses.PENDING,
                        quizInvitationKey: emailKeys[quizPlayer.id]
                      }
                    : quizPlayer
            )
        }))
    } as Quiz;
    return updatedQuiz;
  }

  export function UpdateStateWithOnDeletedQuiz(quizTableRows: QuizTableRow[], quizIdToBeDeleted: number): QuizTableRow[]
  {
        var updateQuizRows = [...quizTableRows.filter(quiz => quiz.id != quizIdToBeDeleted)]
        return updateQuizRows;
  }