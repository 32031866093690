import { useEffect, useRef, useState } from 'react';
import { QuestionMobileProps } from '../../Properties/QuestionListMobileProps';
import { useAuth } from 'react-oidc-context';
import { UpdateQuizWithAttachmentAdd, UpdateQuizWithDeletedAttachment, UpdateQuizWithDeletedQuestion, UpdateQuizWithUpdatedRoundAndQuestionChange, UpdateQuizWithUpdatedRoundAndUpdatedQuestion } from '../../Shared/QuestionStateHelper';
import { UpdateQuestion } from '../../../../interfaces/Questions/UpdateQuestion';
import { QuizRepo } from '../../../Quiz/Repo/QuizRepo';
import { QuestionFromRound } from '../../../../interfaces/Quiz/QuestionFromRound';
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CreateOrEditQuestionPopup from '../CreateOrEditQuestionPopup';
import { CreateOrEditQuestion } from '../../../../interfaces/Questions/CreateOrEditQuestion';
import { CreateQuestion } from '../../../../interfaces/Questions/CreateQuestion';
import { Question } from '../../../../interfaces/Quiz/Question';
import SortableQuestionMobile from '../SortableQuestion/SortableQuestionMobile';
import { FileUploadStatus } from '../../../Shared/Components/FileUpload/Enum/FileUploadStatus';
import { AttachmentSource } from '../../../Shared/Components/FileUpload/Enum/AttachmentSource';
import { AddAttachment } from '../../../Shared/Misc/AddAttachment';
import { QuestionRepo } from '../../Repo/QuestionRepo';
import { UpdateQuizWithCreateAnswer, UpdateQuizWithDeleteAnswer, UpdateQuizWithUpdatedAnswer } from '../../../Answer/AnswerStateHelper';
import { AnswerRepo } from '../../../Answer/Repo/AnswerRepo';
import { UpdateAnswer } from '../../../../interfaces/Answers/UpdateAnswer';
import { CreateAnswer } from '../../../../interfaces/Answers/CreateAnswer';
import { Answer } from '../../../../interfaces/Quiz/Answer';
import { Attachment } from '../../../Shared/Components/FileUpload/interface/Attachment';

function QuestionListMobile({
    selectedRound, quiz,
    onQuizChange }: QuestionMobileProps) {

    const auth = useAuth();
    const quizRepo = new QuizRepo(auth);
    const questionRepo = new QuestionRepo(auth);
    const answerRepo = new AnswerRepo(auth);
    const descriptionRef = useRef<HTMLInputElement | null>(null);
    const [shouldOpenCreateQuestionModel, setShouldOpenQuestionCreateModel] = useState<boolean>(false)

    useEffect(() => {
    }, [selectedRound, auth]);

    async function onQuestionChange(updatedQuestion: UpdateQuestion) {
        await quizRepo.UpdateQuestion(async () => {
            let updatedQuiz = UpdateQuizWithUpdatedRoundAndQuestionChange(quiz, selectedRound, updatedQuestion)
            onQuizChange(updatedQuiz);
        }, updatedQuestion);
    }

    async function onQuestionAdd(newQuestion:Question) {
        let createQuestion:CreateQuestion = {
            introductionText: newQuestion.introductionText,
            questionText: newQuestion.questionText,
            sequence: newQuestion.sequence,
            roundId: newQuestion.roundId,
            quizId: quiz.id,
            answers: newQuestion.answers,
            attachments: newQuestion.attachments,
            maxPoints: newQuestion.maxPoints
        };

        await quizRepo.CreateQuestion((questionId, answerId) => {
            let questionForUi: Question = {
                questionText: createQuestion.questionText,
                introductionText: createQuestion.introductionText,
                roundId: createQuestion.roundId,
                sequence: createQuestion.sequence,
                id: questionId,
                answers: createQuestion.answers,
                attachments: createQuestion.attachments,
                maxPoints: createQuestion.maxPoints,
                quizId: quiz.id
            }
            let updatedQuiz = UpdateQuizWithUpdatedRoundAndUpdatedQuestion(quiz, selectedRound, questionForUi, newQuestion.sequence, answerId);
            onQuizChange(updatedQuiz);      
        }, createQuestion);
    }

    async function handleOnDeleteQuestion(questionId: number) {
        await quizRepo.DeleteQuestion(async () => {
            let updatedQuiz = UpdateQuizWithDeletedQuestion(quiz, selectedRound, questionId)
            onQuizChange(updatedQuiz);
        }, questionId);
    }

    async function handleonAddQuestionClick() {
        setShouldOpenQuestionCreateModel(true);
    }

    function createNewDefaultQuestion () : CreateOrEditQuestion {
        return {
            answer: "",
            questionText: "",
            introductionText: "",
            isNewQuestion: true,
            sequence: selectedRound.questionsFromRound.length + 1,
            roundName: selectedRound.name,
            answers:  [] as Answer[],
            attachments: [] as Attachment[],
            maxPoints: selectedRound.questionsFromRound.length,
            quizId: quiz.id,
            roundId: selectedRound.id
        } as CreateOrEditQuestion
    }

    const handleAddUrlAttachment = async (url: string, source: AttachmentSource, questionId: number) =>
        {
            const result = await questionRepo.AddAttachmentAsync(questionId,{
            Source: source,
            Url: url
            } as AddAttachment );
    
            if(result instanceof Error)
            {
            }
            else
            {
                let updatedQuiz = UpdateQuizWithAttachmentAdd(quiz, selectedRound.id, questionId, result)
                onQuizChange(updatedQuiz);
            }
        }
    
        const handleDeleteAttachment = async (attachmentId: number, questionId: number) => {
            await questionRepo.DeleteAttachmentAsync(questionId, attachmentId);
            let updatedQuiz = UpdateQuizWithDeletedAttachment(quiz, selectedRound.id, questionId, attachmentId);
            onQuizChange(updatedQuiz);
        }
    
        const handleFileUploadAttachment = async (file: File, questionId: number): Promise<FileUploadStatus> => {
            const result = await questionRepo.UploadFileAsync(questionId, file);
            if (result instanceof Error) 
            {
                return FileUploadStatus.Fail;
            } 
            else 
            {
                let updatedQuiz = UpdateQuizWithAttachmentAdd(quiz, selectedRound.id, questionId, result)
                onQuizChange(updatedQuiz);
              return FileUploadStatus.Succeeded;
            }
        };
    
        const handleAddAnswer = async (questionId: number) => {
            let answerId = await answerRepo.CreateAnswerAsync({ questionId: questionId, description: ""} as CreateAnswer);
            let updateQuiz = UpdateQuizWithCreateAnswer(quiz, selectedRound.id, questionId, answerId);
            onQuizChange(updateQuiz);
        }
    
        const handleUpdateAnswer = async (questionId: number, description: string, answerId: number) => {
            await answerRepo.UpdateAnswerAsync({ id: answerId, questionId: questionId, description: description } as UpdateAnswer);
            let updateQuiz = UpdateQuizWithUpdatedAnswer(quiz, selectedRound.id, questionId, answerId, description);
            onQuizChange(updateQuiz);
        }
    
        const handleDeleteAnswer = async (answerId: number, questionId: number) => {
            await answerRepo.DeleteAnswerAsync(answerId);
            let updateQuiz = UpdateQuizWithDeleteAnswer(quiz, selectedRound.id, questionId, answerId);
            onQuizChange(updateQuiz);
        }

    return (
        <>
            <div>
                {
                    <Button
                        variant="outlined"
                        startIcon={<AddIcon className='AddQuestionIconButton' />}
                        fullWidth
                        className="question-list-element"
                        onClick={handleonAddQuestionClick}
                    >
                    </Button>
                }
            </div>
            <div id="question-list">
                {quiz.rounds.find(x => x.id == selectedRound.id)
                    .questionsFromRound
                    .sort((a: QuestionFromRound, b: QuestionFromRound) => a.sequence - b.sequence)
                    .map((q) => (
                        <SortableQuestionMobile
                            key={`${q.id}`}
                            initialQuestion={q}
                            quiz={quiz}
                            selectedRound={selectedRound}
                            descriptionRef={descriptionRef}
                            onQuestionChange={onQuestionChange}
                            onDeleteQuestion={handleOnDeleteQuestion}
                            onAddAnswer={handleAddAnswer}
                            onDeleteAnswer={handleDeleteAnswer}
                            onUpdateAnswer={handleUpdateAnswer}
                            onAddUrlAttachment={handleAddUrlAttachment}
                            onDeleteAttachment={handleDeleteAttachment}
                            onFileAttachmentUpload={handleFileUploadAttachment}
                        />
                    ))}
            </div>

                    {
                        selectedRound &&  <CreateOrEditQuestionPopup
                    initialQuestion={createNewDefaultQuestion()}
                    shouldOpenPopup={shouldOpenCreateQuestionModel}
                    selectedRound={selectedRound}
                    onQuestionChange={onQuestionAdd}
                    setShouldOpenPopup={setShouldOpenQuestionCreateModel}
                    quiz={quiz}                    ></CreateOrEditQuestionPopup>
                    }
           

        </>
    );
}

export default QuestionListMobile;
