import { AuthContextProps } from "react-oidc-context";
import { CreateQuestion } from "../../../interfaces/Questions/CreateQuestion";
import { QuestionSequence } from "../../../interfaces/Questions/QuestionSequence";
import { UpdateQuestion } from "../../../interfaces/Questions/UpdateQuestion";
import { Question } from "../../../interfaces/Quiz/Question";
import getApiClient from "../../Shared/ApiClient/AxiosClient";
import { AxiosInstance } from "axios";
import { Attachment } from "../../Shared/Components/FileUpload/interface/Attachment";
import { AddAttachment } from "../../Shared/Misc/AddAttachment";


export class QuestionRepo {
  private readonly apiClient:AxiosInstance;
  
  constructor(auth:AuthContextProps) {
    this.apiClient = getApiClient(auth);
  }

  async GetQuestionAsync(id: number): Promise<Question> {
    try {
      const response = await this.apiClient.get(`/question/${id}`)
      return response.data as Question;
    }
    catch (error) {
      throw error
    }
  }

  async UpdateQuestionAsync(updateQuestionDto: UpdateQuestion): Promise<void> {
    try {
      await this.apiClient.put("/question/", updateQuestionDto);
    }
    catch (error) {
      throw error;
    }
  }

  async DeleteQuestionAsync(id: number): Promise<void> {
    try {
      const response = await this.apiClient.delete(`/question/${id}`)
    }
    catch (error) {
      throw error
    }
  }

  async CalculateQuestionSequencesAsync(questionSequenceDto: QuestionSequence): Promise<void> {
    try {
      const response = await this.apiClient.post(`/question/sequence`, questionSequenceDto);
    }
    catch (error) {
      throw error
    }
  }

  async UploadFileAsync(questionId: number, file: File): Promise<Attachment | Error> {
    const formData = new FormData();
    formData.append('files', file);

    try {
        const response = await this.apiClient.post(`/question/${questionId}/attachment/upload`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });

        return response.data as Attachment; 
    } catch (error) {
        return new Error(error.response?.data?.message || 'Uploaden bestand mislukt.');
    }
}

async DeleteAttachmentAsync(questionId: number, attachmentId: number): Promise<void> {
  try {
    const response = await this.apiClient.delete(`/question/${questionId}/attachment/${attachmentId}`)
  }
  catch (error) {
    throw error
  }
}
async AddAttachmentAsync(questionId: number, addAttachment: AddAttachment): Promise<Attachment | Error> {
  try {
      const formData = new FormData();
      formData.append("Url", addAttachment.Url);
      formData.append("Source", addAttachment.Source.toString());

      const response = await this.apiClient.post(`/question/${questionId}/attachment`, formData, {
          headers: {
              'Content-Type': 'multipart/form-data'
          }
      });

      return response.data as Attachment; 
  } catch (error) {
      return new Error(error.response?.data?.message);
  }
}



}

