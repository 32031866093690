import { FC, useEffect, useState } from "react";
import { TextField, Button, MenuItem, Select, InputLabel, FormControl, SelectChangeEvent, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { Gender } from "../Enum/Gender";
import { UserInfo as UserInfoInterface } from "../../../interfaces/User/UserInfoInterface";
import { UserInfoProps } from "../Props/UserInfoProps";
import { useAuth } from "react-oidc-context";
import { UserRepo } from "../Repo/UserRepo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import SaveIcon from '@mui/icons-material/Save';
import SnackbarNotification from "../../Shared/Snackbar/SnackbarNotifcation";
import { IsEmailValid } from "../../Shared/Email/EmailHelper";

const UserInfo: FC<UserInfoProps> = ({ }) => {
    const [showSnackbar, setShowSnackbar] = useState<boolean>(false);
    const [userData, setUserData] = useState<UserInfoInterface>({} as UserInfoInterface);
    const [errors, setErrors] = useState<{ [key: string]: string }>({});
    const [isLoading, setIsLoading]  = useState<boolean>(true);
    const auth = useAuth();
    const userRepo = new UserRepo(auth);

    useEffect(() => {
        const userId = auth.user.profile.sub;
        
        userRepo.GetUserInfo((userInfo) => {
            setUserData(userInfo as UserInfoInterface);
            setIsLoading(false);
        }, userId)
    }, [auth]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setUserData({ ...userData, [name]: value });
    };

    const handleGenderChange = (event: SelectChangeEvent<Gender>) => {
        setUserData({ ...userData, gender: event.target.value as Gender });
    };

    const handleDateChange = (date: Dayjs | null) => {
        setUserData({ ...userData, birthdate: date ? date.toDate() : null });
    };

    const handleSubmit = () => {
        const validationErrors: { [key: string]: string } = {};
        
        const trimmedEmail = userData.email.trim();
        if (!trimmedEmail || !IsEmailValid(trimmedEmail)) {
            validationErrors.email = "Ongeldig email address.";
        }

        if (Object.keys(validationErrors).length === 0) {
            userRepo.UpdateUserInfo(() => {
                setShowSnackbar(true);
            }, userData.id, userData);
        }

        setErrors(validationErrors);
    };

    return (
        <>
            {
                isLoading && <>...IsLoading</>
            }

            <Box className="userinfo-container">
                <Typography variant="h5" component="h1" gutterBottom>
                    Account Info
                </Typography>
                <TextField
                    label="Username"
                    name="userName"
                    value={userData.userName || ""}
                    onChange={handleChange}
                    fullWidth
                />
                <TextField
                    label="Voornaam"
                    name="firstName"
                    value={userData.firstName || ""}
                    onChange={handleChange}
                    fullWidth
                />
                <TextField
                    label="Familienaam"
                    name="lastName"
                    value={userData.lastName || ""}
                    onChange={handleChange}
                    fullWidth
                />
                <TextField
                    label="Email"
                    name="email"
                    value={userData.email || ""}
                    onChange={handleChange}
                    fullWidth
                    error={!!errors.email}
                    helperText={errors.email}
                />

                <FormControl fullWidth>
                    <InputLabel>Gender</InputLabel>
                    <Select
                        label="Gender"
                        value={userData.gender || 0}
                        onChange={handleGenderChange}
                        name="gender"
                    >
                        <MenuItem value={Gender.Male}>Man</MenuItem>
                        <MenuItem value={Gender.Female}>Vrouw</MenuItem>
                        <MenuItem value={Gender.NonBinary}>Non-Binair</MenuItem>
                        <MenuItem value={Gender.Other}>Ander</MenuItem>
                        <MenuItem value={Gender.PreferNotToSay}>Zeg ik liever niet</MenuItem>
                        <MenuItem value={Gender.NotChosen}>/</MenuItem>
                    </Select>
                </FormControl>

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        label="Geboortedatum"
                        value={userData.birthdate ? dayjs(userData.birthdate) : null}
                        onChange={handleDateChange}
                        className="form-input"
                        format="DD/MM/YYYY"
                        slotProps={{
                            actionBar: {
                                actions: ['clear'],
                            },
                        }}
                    />
                </LocalizationProvider>

                <Button variant="contained" startIcon={<SaveIcon />} onClick={handleSubmit}>
                    Opslaan Account data
                </Button>
            </Box>

            <SnackbarNotification
                textToDisplay="Account info opgeslagen"
                setShouldOpenSnackbar={setShowSnackbar}
                shouldOpenSnackbar={showSnackbar}
                horizontalLocation='center'
                verticalLocation='top'
                severity='success'
            />
        </>
    );
};

export default UserInfo;
