import React, { useState, ChangeEvent, useEffect, FormEvent} from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TextField, FormControlLabel, FormGroup, Button, Switch, Tooltip, Box, Alert, Typography, } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import { SaveQuizData } from '../../../interfaces/Quiz/SaveQuizData';
import { Quiz } from '../../../interfaces/Quiz/Quiz';
import { useAuth } from 'react-oidc-context';
import ChangedDataDialog from '../../Shared/Components/DataChanged/Components/ChangedDataDialog';
import { areDatesEqual, getDateTimeString } from '../../Shared/Date/DateHelper';
import AddressComponent from '../../Shared/Components/Address/Componenets/AddressComponent';
import { Address } from '../../Shared/Components/Address/Interface/Address';
import { ChangedDataFromOriginalItem } from '../../Shared/Components/DataChanged/Interface/ChangedDataItem';
import { ChangedDataFromOriginalActionButtonProps } from '../../Shared/Button/ChangedDataFromOriginalActionButtonProps';

interface QuizDataProps {
  quiz: Quiz;
  saveQuizData: (data: SaveQuizData) => Promise<void>;
  setUpdatedQuiz: (quiz: Quiz) => void;
  checkChangedQuizData: boolean;
  onSendMailsClick: () => void;
}

const QuizData: React.FC<QuizDataProps> = ({ quiz, saveQuizData, setUpdatedQuiz, checkChangedQuizData, onSendMailsClick }) => {
  const [quizName, setQuizName] = useState('');
  const [startDate, setStartDate] = useState<Dayjs | null>(null);
  const [publicationDate, setPublicationDate] = useState<Dayjs | null>(null);
  const [isPublished, setIsPublished] = useState(false);
  const [address, setAddress] = useState<Address>(quiz.address);
  const originalQuizData = {...quiz} as Quiz;
  const [changedQuizData, setChangedQuizData] = useState<ChangedDataFromOriginalItem[]>();
  const [showChangedQuizDataDialog, setShowChangedQuizDataDialog] = useState<boolean>(false);

  const auth = useAuth();

  useEffect(() => {
    setQuizName(quiz.name);
    setIsPublished(quiz.isPublished);

    if (quiz.startDate != null) {
      setStartDate(dayjs(quiz.startDate));
      setPublicationDate(dayjs(quiz.publishDate));
    }
  }, [quiz, auth]);

  const handleOnStartDateChange = (date: Dayjs) => {
      setStartDate(date);
      const newPublicationDate = date.add(1, 'days');
      setPublicationDate(newPublicationDate);
  };

  const handleOnPublicationDateChange = (date: Dayjs) => {
    setPublicationDate(date);
};

  const handleIsPublishedChange = (event: ChangeEvent<HTMLInputElement>) => {
    setIsPublished(event.target.checked);
  };

  const handleQuizDataOnSubmit = async (event: FormEvent) => {
    event.preventDefault();

    const formData: SaveQuizData = {
      quizId: quiz.id,
      name: quizName,
      startDate: startDate ? setTimeZoneOffset(startDate.toDate()) : null,
      publishDate: publicationDate ? setTimeZoneOffset(publicationDate.toDate()) : null,
      isPublished: isPublished,
      address: {id: quiz.address.id, ...address}
    };

    await saveQuizData(formData);
    checkChangedData();
  };

  const checkChangedData = () =>  {
    if (!checkChangedQuizData) return;
    const changedData = getChangedData();

    if(changedData.length > 0){
      setChangedQuizData(changedData);
      setShowChangedQuizDataDialog(true);
    }
  };

  function getChangedData(): ChangedDataFromOriginalItem[] {
    const changedData = [] as ChangedDataFromOriginalItem[];

    originalQuizData.startDate = new Date(originalQuizData.startDate);
    if(!areDatesEqual(originalQuizData.startDate, startDate.toDate())) changedData.push({ propertyName: "start datum", originalValue: getDateTimeString(originalQuizData.startDate, "DDDD DD/MM/YYYY"), newValue: getDateTimeString(startDate.toDate(), "DDDD DD/MM/YYYY")} as ChangedDataFromOriginalItem);

    if(originalQuizData.address.street !== address.street) changedData.push({ propertyName: "straat", originalValue: originalQuizData.address.street, newValue: address.street} as ChangedDataFromOriginalItem);
    if(originalQuizData.address.number !== address.number) changedData.push({ propertyName: "nummer", originalValue: originalQuizData.address.number, newValue: address.number} as ChangedDataFromOriginalItem);
    if(originalQuizData.address.town !== address.town) changedData.push({ propertyName: "stad", originalValue: originalQuizData.address.town, newValue: address.town} as ChangedDataFromOriginalItem);
    if(originalQuizData.address.postalcode !== address.postalcode) changedData.push({ propertyName: "postcode", originalValue: originalQuizData.address.postalcode, newValue: address.postalcode} as ChangedDataFromOriginalItem);
    if(originalQuizData.address.province !== address.province) changedData.push({ propertyName: "provincie", originalValue: originalQuizData.address.province, newValue: address.province} as ChangedDataFromOriginalItem);
    if(originalQuizData.address.country !== address.country) changedData.push({ propertyName: "land", originalValue: originalQuizData.address.country, newValue: address.country} as ChangedDataFromOriginalItem);

    return changedData;
  }

  function setTimeZoneOffset(date: Date, targetOffset = 0) {
    const currentOffset = date.getTimezoneOffset();
    const newDate = new Date(date);

    const offsetDifference = targetOffset - currentOffset;
    newDate.setMinutes(newDate.getMinutes() + offsetDifference);

    return newDate;
  }

  const handleCloseDataChangedDialog = () => {
    setShowChangedQuizDataDialog(false);
  }

  const handleAddressChange = (newAddress: Address) => {
    setAddress(newAddress);
  };

  const changedDataActions: ChangedDataFromOriginalActionButtonProps[] = [
    {
        buttonText: "Uitnodigingen hersturen",
        toolltipText: "De invitatie pagina wordt geopend",
        onButtonClick: () => {
            onSendMailsClick();
            setShowChangedQuizDataDialog(false);
        },
        variant: "contained"
    },
    {
      buttonText: "sluiten",
      toolltipText: null,
      onButtonClick: () => {
          setShowChangedQuizDataDialog(false);
      },
      variant: "contained"
  }
];

  return (
    <>
      <form id="quiz-data-form" onSubmit={handleQuizDataOnSubmit}>
        <TextField
          label="Naam"
          variant="outlined"
          value={quizName}
          className='form-input'
          onChange={(e) => setQuizName(e.target.value)}
          required={true}
        />

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateTimePicker
            label="Startdatum"
            onChange={(date) => handleOnStartDateChange(date)}
            value={startDate}
            className='form-input'
            format="DD/MM/YYYY HH:mm"
            ampm={false}
            slotProps={{
              actionBar: {
                actions: ['clear']
              }
            }}
          />

          <DateTimePicker
            label="Publiceerdatum"
            onChange={(date) => handleOnPublicationDateChange(date)}
            value={publicationDate}
            className='form-input'
            format="DD/MM/YYYY HH:mm"
            ampm={false}
            slotProps={{
              actionBar: {
                actions: ['clear']
              }
            }}
          />
        </LocalizationProvider>

        <FormGroup>
          <FormControlLabel
            control={<Switch checked={isPublished} onChange={handleIsPublishedChange} />}
            className='form-input'
            label="Gepubliceerd"
          />
        </FormGroup>
        
        <AddressComponent address={address} onAddressChange={handleAddressChange} showAddressAutocomplete={true} showAddressMap={true}/>

        <Button variant="contained" startIcon={<SaveIcon />} type='submit'>
          Opslaan Quiz
        </Button>
      </form>


      <ChangedDataDialog open={showChangedQuizDataDialog} title='Er is praktische data gewijzigd.'
         buttonActionsInfo={changedDataActions} changedDataItems={changedQuizData}
         onClose={handleCloseDataChangedDialog}/>
    </>
  );
};

export default QuizData;
