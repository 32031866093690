import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField
} from "@mui/material";
import { EditQuizTeamProps } from "../Props/EditQuizTeamProps";

const EditQuizTeam: React.FC<EditQuizTeamProps> = ({
  quizTeam,
  open,
  onClose,
  onQuizTeamChange
}) => {
    const [quizTeamName, setQuizTeamName] = useState<string>(quizTeam.name)

    function handleOnQuizTeamChange() {
        onQuizTeamChange({...quizTeam, 
            name: quizTeamName
        })
        onClose();
    }

  return (
    <Dialog className="edit-quizteam-dialog" open={open} onClose={onClose} fullWidth>
      <DialogTitle>Wijzig team - {quizTeam.name}</DialogTitle>
      <DialogContent>
        <TextField
            label="Naam"
            className="edit-quizteam-name"
            value={quizTeamName}
            fullWidth
            onChange={(e) => setQuizTeamName(e.target.value)}/>        
      </DialogContent>
      <DialogActions>
        <Button onClick={handleOnQuizTeamChange} color="primary" variant="contained">
          Opslaan
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditQuizTeam;
