import { FC, useEffect, useState } from 'react';
import { QuizRepo } from '../Repo/QuizRepo';
import { QuizTableRow } from '../../../interfaces/Quiz/QuizTableRow';
import { GridColDef, GridRowParams } from '@mui/x-data-grid';
import StyledDataGrid from '../../Shared/DataGrid/StyledDataGrid';
import AddIcon from '@mui/icons-material/Add';
import "../../Shared/DataGrid/Datagrid.scss";
import { useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import { QuizListItem } from '../../../interfaces/Quiz/QuizListItem';
import { useAuth } from 'react-oidc-context';
import { UserRoles } from '../../Shared/Misc/UserRoles';
import SnackbarNotification from '../../Shared/Snackbar/SnackbarNotifcation';
import { IconButton } from '@mui/material';
import DeleteIcon from "@mui/icons-material/Delete";
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import LeaderboardDialog from '../../Scores/Components/LeaderBoardDialog';
import { LeaderboardShowOptions } from '../../Scores/Enum/LeaderBoardShowOptions';
import { LeaderboardWithTotalPointsOfQuiz } from '../../../interfaces/Quiz/LeaderboardWithTotalPointsOfQuiz';
import DeleteModal from '../../Shared/Delete/DeleteModal';
import { LeaderBoardRepo } from '../../Scores/Repo/LeaderBoardRepo';
import { getDateTimeString } from '../../Shared/Date/DateHelper';
import { UpdateStateWithOnDeletedQuiz } from '../Shared/QuizStateHelper';

  function generateGridRow(quizItem: QuizListItem): QuizTableRow {
    return {
      id: quizItem.id,
      name: quizItem.name,
      ownerId: quizItem.ownerId,
      ownerUserName: quizItem.ownerUserName,
      startDate: quizItem.startDate,
      publishDate: quizItem.publishDate,
      isPublished: quizItem.isPublished,
      isQuizOwner: quizItem.isQuizOwner
    } as QuizTableRow
  }

  const QuizIndexTable: FC = () => {
    const navigate = useNavigate();
    const auth = useAuth();
    const quizRepo = new QuizRepo(auth);
    const leaderboardRepo = new LeaderBoardRepo(auth);
    const [quizzesWithPlayers, setQuizzesWithPlayers] = useState<QuizTableRow[]>([]);
    const [showSnackbar, setShowSnackbar] = useState<boolean>(false);
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [quizIdToBeDeleted, setQuizIdToBeDeleted] = useState<number>();
    const [quizNameToBeDeleted, setQuizNameToBeDeleted] = useState<string>();
    const [showLeaderBoardDialog, setShowLeaderBoardDialog] = useState<boolean>(false);
    const [leaderboard, setLeaderboard] = useState<LeaderboardWithTotalPointsOfQuiz>({
      leaderboard: [], 
      totalPointsOfQuiz: 0, 
    });

    const getColumnDefinitions = () => {
      const baseColumns: GridColDef[] = [
        { headerName: "Id", field: "id", headerClassName: 'tableHeader', flex: 1 },
        { headerName: "Quiz Naam", field: "name", headerClassName: 'tableHeader', flex: 1, },
        { headerName: "Quizmaster", field: "ownerUserName", headerClassName: 'tableHeader', flex: 1, },
        {
          headerName: "Start", field: "startDate", headerClassName: 'tableHeader', flex: 1,
          renderCell: (params) => {
            if (!params.row.startDate) return (<></>);
            return (
              <>
                {getDateTimeString(new Date(params.row.startDate), "DDDD D MMMM YYYY HH:mm")}
              </>
            )
          }
        },
        {
          headerName: "Publiceerdatum", field: "publishDate", headerClassName: 'tableHeader', flex: 1,
          renderCell: (params) => {
            if (!params.row.publishDate) return (<></>)
            return (
              <>
                {getDateTimeString(new Date(params.row.publishDate), "DDDD D MMMM YYYY HH:mm")}
              </>
            )
          }
        },
        {
          headerName: "Gepubliceerd", field: "isPublished", headerClassName: 'tableHeader', flex: 1, type: 'boolean'
        },
        {
          field: "",
          headerName: "Acties",
          headerClassName: "tableHeader",
          sortable: false,
          flex: 1,
          minWidth: 175,
          renderCell: (params) => {
            const isOwner = params.row.isQuizOwner;
            const isAdmin = auth.user?.profile.role === UserRoles.Admin;
            const isPublished = params.row.isPublished;
            
            return (
              <>
                <IconButton
                  onClick={() => onEditQuizClick(params.row)}
                  disabled={!isOwner && !isAdmin}
                  color={isOwner || isAdmin ? "primary" : "default"}
                >
                  <EditIcon />
                </IconButton>
        
                <IconButton
                  onClick={() => onPowerPointClick(params.row)}
                  disabled={!isOwner && !isPublished && !isAdmin}
                  color={isOwner || isPublished || isAdmin ? "primary" : "default"}
                >
                  <AutoAwesomeMotionIcon/>
                </IconButton>
        
                <IconButton
                  onClick={() => onLeaderBoard(params.row)}
                  disabled={!isOwner && !isPublished && !isAdmin}
                  color={isOwner || isPublished || isAdmin ? "primary" : "default"}
                >
                  <EmojiEventsIcon/>
                </IconButton>
        
                <IconButton
                  onClick={() => onDeleteQuizClick(params.row)}
                  disabled={!isOwner && !isAdmin}
                  color={isOwner || isAdmin ? "primary" : "default"}
                >
                  <DeleteIcon />
                </IconButton>
              </>
            );
          },
        }     
      ];

      return baseColumns;
    };

    function onPowerPointClick(row: QuizTableRow) {
      const fileName = getDateTimeString(new Date()) + "_" + row.name + ".pptx";
      quizRepo.GeneratePowerpoint(() => {
  
      },
        row.id, fileName)
    }
    
    function onLeaderBoard(row: QuizTableRow) {
        const quizId = row.id; 
          leaderboardRepo.GetLeaderboardByQuizId((data: LeaderboardWithTotalPointsOfQuiz) => {
          setLeaderboard(data);
          setShowLeaderBoardDialog(true);
        }, quizId);
    }
    
    function onDeleteQuizClick(row: QuizTableRow) {
      setShowDeleteModal(true);
      setQuizNameToBeDeleted(row.name);
      setQuizIdToBeDeleted(row.id);
    }

    function onDeleteQuizSubmit(){
        quizRepo.DeleteQuiz(() => {
          setQuizzesWithPlayers(UpdateStateWithOnDeletedQuiz(quizzesWithPlayers, quizIdToBeDeleted))
          setQuizNameToBeDeleted("");
          setQuizIdToBeDeleted(0);
          setShowDeleteModal(false);
        }, quizIdToBeDeleted);
    }
    

    function onEditQuizClick(row: QuizTableRow) {
      if(row.isQuizOwner || auth.user?.profile.role == UserRoles.Admin){
        navigate(`/quiz/edit/${row.id}`)
      }
      else {
        setShowSnackbar(true);
      }
    }

    const handleCloseLeaderboardDialog = () => {
      setShowLeaderBoardDialog(false);
    }

    const handleRowDoubleClick = (params: GridRowParams) => {
      const row = params.row;
      onEditQuizClick(row);
    };

    useEffect(() => {
      const fetchData = async () => {
        await quizRepo.GetAllQuizzes(mapQuizToQuizTableRow)

        function mapQuizToQuizTableRow(quizListItems: QuizListItem[]) {
          let mappedQuizzes = quizListItems.map((quizListItem: QuizListItem) => {
            return generateGridRow(quizListItem);
          }) as QuizTableRow[];
          setQuizzesWithPlayers(mappedQuizzes);
        }
      }

      fetchData()
        .catch(console.error);
    }, [auth]);

    return (
      <>
        <div className='tableHeaderContainer'>
          <h1>Quizoverzicht</h1>
          <a className='pointerMouse' onClick={() => navigate("/quiz/add")}><AddIcon fontSize='large'></AddIcon></a>
        </div>

        <div className="tableInnerContainer">
          <StyledDataGrid onRowDoubleClick={handleRowDoubleClick} columns={getColumnDefinitions()} dataset={quizzesWithPlayers} children={null} noRowsText='U hebt geen deelnemende quizzen'></StyledDataGrid>
          {
            <SnackbarNotification
              textToDisplay='Onvoldoende rechten voor actie'
              setShouldOpenSnackbar={setShowSnackbar}
              shouldOpenSnackbar={showSnackbar}
              horizontalLocation='center'
              verticalLocation='top'
              severity='warning'
              >
            </SnackbarNotification>
          }
        </div>
        <LeaderboardDialog fullLeaderBoard={leaderboard.leaderboard} totalPointsQuiz={leaderboard.totalPointsOfQuiz}
          onLeaderBoardDialogClose={handleCloseLeaderboardDialog} showOption={LeaderboardShowOptions.All}
          fullScreenOpen={showLeaderBoardDialog} />

          <DeleteModal
            deleteCallback={() => onDeleteQuizSubmit()}
            setOpenModal={setShowDeleteModal}
            openModal={showDeleteModal}
            subject='quiz'
            subjectValue={quizNameToBeDeleted}/>
      </>
    );
  };

  export default QuizIndexTable;
