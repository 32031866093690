import { ScoresProps } from "../../Props/Scores/ScoresProps";
import { useMediaQuery } from "react-responsive"
import ScoresFullScreenComponent from "./ScoresFullScreenComponent";
import { TeamRoundPoint } from "../../../../interfaces/Quiz/TeamRoundPoint";
import ScoresMobileComponent from "./ScoresMobileComponent";
import { UpdateQuizWithNewTeamRoundPoints } from "../../Shared/QuizTeamRoundStateHelper";
import { ScoresSubComponentProps } from "../../Props/Scores/ScoresSubComponentProps";
import { useAuth } from "react-oidc-context";
import { ScoresRepo } from "../../Repo/ScoresRepo";
import { UNASSIGNED_TEAM_ID } from "../../../Shared/Constants/UnassignedTeamId";

const ScoresHead: React.FC<ScoresProps> = ({quiz, setUpdatedQuiz}) => {
    const auth = useAuth();
    const scoreRepo = new ScoresRepo(auth);

    const isTabletOrMobile = useMediaQuery({query: '(max-width: 1224px)'})
    const isDesktopOrLaptop = useMediaQuery({query: '(min-width: 1224px)'})
    const totalQuizScore = quiz.rounds.reduce((total, round) => total + round.maxPoints, 0);

    function onTeamRoundPointChange(teamRoundPoint: TeamRoundPoint){
        let updatedQuiz = UpdateQuizWithNewTeamRoundPoints(quiz, teamRoundPoint.quizTeamId, teamRoundPoint.roundId, teamRoundPoint.points);
        setUpdatedQuiz(updatedQuiz);
    }

    function handleScoreUpdateDatabase(teamRoundPoint: TeamRoundPoint)
    {
      var isValidPoint = !isNaN(parseFloat(teamRoundPoint.points));
  
      scoreRepo.UpdateQuizTeamRoundScore(() => {
        }, quiz.id, teamRoundPoint.quizTeamId, teamRoundPoint.roundId, isValidPoint ? parseFloat(teamRoundPoint.points) : 0);
    }
  
    const handleScoreChange = (teamRoundPoint: TeamRoundPoint) => {
        teamRoundPoint.points = teamRoundPoint.points.replace(".", ",").replace(/[^0-9,]/g, "");
        
      if(isNaN(parseFloat(teamRoundPoint.points)) && !teamRoundPoint.points.startsWith(",")){
        teamRoundPoint.points = "";
        onTeamRoundPointChange(teamRoundPoint)
        return;
      }

      onTeamRoundPointChange(teamRoundPoint);
    };

    const sharedScoresProps = {
        quiz: quiz,
        totalQuizPoints: totalQuizScore,
        changeTeamRoundPointState: handleScoreChange,
        saveTeamRoundPoint: handleScoreUpdateDatabase
    } as ScoresSubComponentProps;

    return (
        <>
            {isDesktopOrLaptop && <ScoresFullScreenComponent {...sharedScoresProps} />}
            {isTabletOrMobile && <ScoresMobileComponent {...sharedScoresProps} />}
        </>
    );    
}

export default ScoresHead;