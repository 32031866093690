import { QuizPlayer } from "../../../interfaces/QuizPlayer/QuizPlayer";
import { QuizTeamWithQuizPlayers } from "../../../interfaces/QuizTeam/QuizTeamWithQuizPlayers";

  export function GetNewGuestQuizTeam(quizTeamId: number, guestQuizTeamName: string, quizPlayers: QuizPlayer[]) : QuizTeamWithQuizPlayers {
    const newQuizTeam: QuizTeamWithQuizPlayers = {
      id: quizTeamId,
      teamId: null,
      isGuestTeam: true,
      name: guestQuizTeamName,
      quizPlayers: quizPlayers,
      focusOnQuizTeam: true
    };

    return newQuizTeam;
  }