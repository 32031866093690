import React, { useState, useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import "../Confirmation.scss";
import '../../Shared/Misc/GeneralStyling.scss';
import InvalidKeyComponent from '../Components/InvalidKeyComponent';
import ConfirmationMessage from '../Components/ConfirmationMessage';
import { MailRepo } from '../Repo/MailRepo';
import { ConfirmationViewProps } from '../Props/ConfirmationViewProps';
import { ConfirmationType } from '../Enum/ConfirmationType';
import RegisterToSystemWithQuiz from '../Components/RegisterToSystemWithQuiz';
import Header from '../../Shared/Header/Header';
import InvitationToQuiz from '../Components/InvitationToQuiz';
const HAS_ACCEPTED_KEY = "hasAccepted" as string;

const ConfirmationInvitationView = ({ type }: ConfirmationViewProps) => {
    const { key } = useParams();
    const { userId } = useParams();
    const [searchParams] = useSearchParams();

    const [isValid, setIsValid] = useState<boolean>(false);
    const [isValidating, setIsValidating] = useState<boolean>(true);
    const [invalidEmailReason, setInvalidEmailReason] = useState<string>("");
    const mailRepo = new MailRepo();

    useEffect(() => {
        mailRepo.IsKeyValid((isValidKey: boolean, invalidReason: string = "") => {
            setIsValid(isValidKey);
            setIsValidating(false);

            if(!isValidKey){
                setInvalidEmailReason(invalidReason);
            }
        },
            key);
    }, [key]);

    const ConfirmationContent = () => {
        if (isValidating) {
            return (
                <ConfirmationMessage
                    title="Valideren mail!"
                    message="even geduld."
                    buttonText={null}
                    onButtonClick={null}
                />
            );
        }

        if (!isValid) {
            return <InvalidKeyComponent reason={invalidEmailReason}/>;
        }

        switch (type) {
            case ConfirmationType.InvitationToQuiz:
                if (!searchParams.has(HAS_ACCEPTED_KEY)) return <InvalidKeyComponent reason='Ongeldige actie.'/>;
                var hasAccepted = searchParams.get(HAS_ACCEPTED_KEY) === "true";
                return <InvitationToQuiz confirmationKey={key} hasAccepted={hasAccepted} />;

            case ConfirmationType.RegisterToSystem:
                return <RegisterToSystemWithQuiz confirmationKey={key} userId={userId} />;

            default:
                return <InvalidKeyComponent reason='Ongeldige actie.'/>;
        }
    };

    return (
        <>
            <Header></Header>
            <main>
                <div className='confirmation-container '>
                    {ConfirmationContent()}
                </div>
            </main>
        </>
    );
};

export default ConfirmationInvitationView;
