import React from 'react';
import ConfirmationMessage from './ConfirmationMessage';
import { InvalidKeyComponentProps } from '../Props/InvalidKeyComponentProps';

const InvalidKeyComponent: React.FC<InvalidKeyComponentProps> = ({reason}) => {
  return (
    <>
        <ConfirmationMessage
            title="Mail is ongeldig!"
            message={reason}
            buttonText={null}
            onButtonClick={null}
        />
    </>
  );
};

export default InvalidKeyComponent;
