import { Alert, Box, TextField, Typography } from "@mui/material";
import '../Address.scss'
import AddressAutocomplete from "./AddressAutocomplete";
import { AddressProps } from "../Props/AddressProps";
import AddressMap from "./AddressMap";
import { Address } from "../Interface/Address";

export const AddressComponent: React.FC<AddressProps> = ({ address, onAddressChange, showAddressAutocomplete, showAddressMap }) => {
    const handleAddressChangeProperty = (field: keyof Address) => (event: React.ChangeEvent<HTMLInputElement>) => {
      onAddressChange({ ...address, [field]: event.target.value });
    };

    const handleOnAddressSuggestionClicked = (address: Address) => {
      onAddressChange(address)
    }

    const handleOnLocationMapClicked = (address: Address) => {
      onAddressChange(address);
    }

    return (
      <>    
        <div className="address-container">
          <Typography variant="h6">Adres</Typography>

          <div className="address-widget">
            {showAddressMap && <AddressMap latitude={address.latitude} longitude={address.longitude} onLocationClicked={handleOnLocationMapClicked}/>}
          </div>

          <div className="address-widget">
            {showAddressAutocomplete && <AddressAutocomplete onAddressSuggestionClicked={handleOnAddressSuggestionClicked}/>}
          </div>


          <Box sx={{ width: '100%', padding: 2 }}>
                <Alert severity="error">
                <Typography variant="body1">
                    Alle velden van het adres zijn verplicht in te vullen.
                </Typography>
                </Alert>
            </Box>
          <div className="address-widget">
            <div className="address-fields">
              <TextField label="Straat" value={address.street || ''} onChange={handleAddressChangeProperty('street')} InputLabelProps={{ shrink: Boolean(address.street) }} />
              <TextField label="Nummer" value={address.number || ''} onChange={handleAddressChangeProperty('number')} InputLabelProps={{ shrink: Boolean(address.number) }} />
              <TextField label="Gemeente" value={address.town || ''} onChange={handleAddressChangeProperty('town')} InputLabelProps={{ shrink: Boolean(address.town) }} />
              <TextField label="Postcode" value={address.postalcode || ''} onChange={handleAddressChangeProperty('postalcode')} InputLabelProps={{ shrink: Boolean(address.postalcode) }} />
              <TextField label="Provincie" value={address.province || ''} onChange={handleAddressChangeProperty('province')} InputLabelProps={{ shrink: Boolean(address.province) }} />
              <TextField label="Land" value={address.country || ''} onChange={handleAddressChangeProperty('country')} InputLabelProps={{ shrink: Boolean(address.country) }} />
            </div>
          </div>
        </div>
      </>
    );
};

export default AddressComponent;
