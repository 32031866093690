import { useEffect, useRef, useState } from 'react';
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useAuth } from 'react-oidc-context';
import { QuestionListDesktopProps } from '../../Properties/QuestionListDesktopProps';
import { GenerateUpdatedQuizObjectWithNewSequence, UpdateQuizWithAttachmentAdd, UpdateQuizWithDeletedAttachment, UpdateQuizWithDeletedQuestion, UpdateQuizWithUpdatedRoundAndQuestionChange, UpdateQuizWithUpdatedRoundAndUpdatedQuestion } from '../../Shared/QuestionStateHelper';
import { UpdateQuestion } from '../../../../interfaces/Questions/UpdateQuestion';
import { Question } from '../../../../interfaces/Quiz/Question';
import { CreateQuestion } from '../../../../interfaces/Questions/CreateQuestion';
import { QuizRepo } from '../../../Quiz/Repo/QuizRepo';
import SortableQuestionDesktop from '../SortableQuestion/SortableQuestionDesktop';
import { closestCenter, DndContext, DragEndEvent, MeasuringStrategy, PointerSensor, TouchSensor, useSensor, useSensors } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { QuestionSequence } from '../../../../interfaces/Questions/QuestionSequence';
import { QuestionRepo } from '../../Repo/QuestionRepo';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { QuestionFromRound } from '../../../../interfaces/Quiz/QuestionFromRound';
import { AddAttachment } from '../../../Shared/Misc/AddAttachment';
import { AttachmentSource } from '../../../Shared/Components/FileUpload/Enum/AttachmentSource';
import { FileUploadStatus } from '../../../Shared/Components/FileUpload/Enum/FileUploadStatus';
import { AnswerRepo } from '../../../Answer/Repo/AnswerRepo';
import { UpdateQuizWithCreateAnswer, UpdateQuizWithDeleteAnswer, UpdateQuizWithUpdatedAnswer } from '../../../Answer/AnswerStateHelper';
import { UpdateAnswer } from '../../../../interfaces/Answers/UpdateAnswer';
import { CreateAnswer } from '../../../../interfaces/Answers/CreateAnswer';
import { PIXELS_MENU_PLUS_QUIZ_HEADER } from '../../../Shared/Constants/PixelsMenuAndQuizHeader';
import { Answer } from '../../../../interfaces/Quiz/Answer';
import { Attachment } from '../../../Shared/Components/FileUpload/interface/Attachment';

function QuestionListDesktop({ selectedRound, quiz, onQuizChange, scrollToQuestion, HandleQuestionSelect }: QuestionListDesktopProps) {
    const auth = useAuth();
    const sensors = useSensors(useSensor(PointerSensor), useSensor(TouchSensor));

    const descriptionRef = useRef<HTMLInputElement | null>(null);
    const questionRefs = useRef<Map<Number, HTMLDivElement>>(new Map());

    const quizRepo = new QuizRepo(auth)
    const questionRepo = new QuestionRepo(auth);
    const answerRepo = new AnswerRepo(auth);      
    
    useEffect(() => {
        if (!scrollToQuestion) return;
    
        const activeQuestionElement = questionRefs.current.get(scrollToQuestion.id);
        if (activeQuestionElement) {
            activeQuestionElement.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
    
            const offset = PIXELS_MENU_PLUS_QUIZ_HEADER; 
            const scrollPosition = activeQuestionElement.getBoundingClientRect().top + window.scrollY - offset;
    
            window.scrollTo({
                top: scrollPosition,
                behavior: 'smooth',
            });
        }
    }, [scrollToQuestion]);
    

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        const questionId = Number(entry.target.getAttribute('data-question-id'));
                        const question = selectedRound.questionsFromRound.find((q) => q.id === questionId);
                        if (question) {
                            HandleQuestionSelect(question);
                        }
                    }
                });
            },
            { threshold: 0.5 }
        );

        questionRefs.current.forEach((ref) => {
            if (ref) {
                observer.observe(ref);
            }
        });

        return () => {
            observer.disconnect();
        };
    }, [selectedRound]);

    async function onQuestionChange(updatedQuestion: UpdateQuestion) {
        await quizRepo.UpdateQuestion(async () => {
            let updatedQuiz = UpdateQuizWithUpdatedRoundAndQuestionChange(quiz, selectedRound, updatedQuestion)
            onQuizChange(updatedQuiz);
        }, updatedQuestion);
    }

    async function onQuestionAdd() {
        let sequenceForNewQuestion = Math.max(...selectedRound.questionsFromRound.map(x => x.sequence)) + 1;
        if (selectedRound.questionsFromRound.length === 0) sequenceForNewQuestion = 1

        let createQuestion: CreateQuestion = {
            introductionText: '',
            questionText: '',
            sequence: sequenceForNewQuestion,
            roundId: selectedRound.id,
            quizId: quiz.id,
            answers: [] as Answer[],
            attachments: [] as Attachment[],
            maxPoints: 10
        };

        await quizRepo.CreateQuestion((questionId: number, answerId: number) => {
            let questionForUi: Question = {
                introductionText: createQuestion.introductionText,
                questionText: createQuestion.questionText,
                roundId: createQuestion.roundId,
                sequence: createQuestion.sequence,
                id: questionId,
                answers: [] as Answer[],
                attachments: [] as Attachment[],
                maxPoints: createQuestion.maxPoints,
                quizId: quiz.id
            }

            let updatedQuiz = UpdateQuizWithUpdatedRoundAndUpdatedQuestion(quiz, selectedRound, questionForUi, sequenceForNewQuestion, answerId);
            onQuizChange(updatedQuiz);
        }, createQuestion);
    }

    async function handleOnDeleteQuestion(questionId: number) {
        await quizRepo.DeleteQuestion(async () => {
            let updatedQuiz = UpdateQuizWithDeletedQuestion(quiz, selectedRound, questionId);
            onQuizChange(updatedQuiz);
        }, questionId);
    }

    async function handleAddQuestionClick() {
        onQuestionAdd()
    }

    //TODO move the logic to a helper class or something
    const handleDragEnd = async (event: DragEndEvent) => {
        const { active, over } = event;
        if (!over) return;

        const roundIndex = quiz.rounds.findIndex((round) => round.id === selectedRound.id);
        if (roundIndex === -1) return;

        const foundRound = quiz.rounds[roundIndex];

        const activeQuestion = foundRound.questionsFromRound.find((q) => q.id == active.id);
        const overQuestion = foundRound.questionsFromRound.find((q) => q.id == over.id);

        if (!activeQuestion || !overQuestion) return;

        const activeIndex = foundRound.questionsFromRound.findIndex((q) => q.id === activeQuestion.id);
        const overIndex = foundRound.questionsFromRound.findIndex((q) => q.id === overQuestion.id);

        if (activeIndex !== overIndex) {
            const { updatedQuiz, newArray } = GenerateUpdatedQuizObjectWithNewSequence(foundRound, activeIndex, overIndex, quiz, roundIndex);
            onQuizChange(updatedQuiz);

            const questionSequence: QuestionSequence = {
                sequencedQuestionsIds: newArray.map((question) => question.id)
            };

            await questionRepo.CalculateQuestionSequencesAsync(questionSequence);
        }
    }

    const measuringConfig = {
        droppable: {
            strategy: MeasuringStrategy.Always
        }
    };

    const handleAddAnswer = async (questionId: number) => {
        let answerId = await answerRepo.CreateAnswerAsync({ questionId: questionId, description: "" } as CreateAnswer);
        let updateQuiz = UpdateQuizWithCreateAnswer(quiz, selectedRound.id, questionId, answerId);
        onQuizChange(updateQuiz);
    }

    const handleUpdateAnswer = async (questionId: number, description: string, answerId: number) => {
        await answerRepo.UpdateAnswerAsync({ id: answerId, questionId: questionId, description: description } as UpdateAnswer);
        let updateQuiz = UpdateQuizWithUpdatedAnswer(quiz, selectedRound.id, questionId, answerId, description);
        onQuizChange(updateQuiz);
    }

    const handleDeleteAnswer = async (answerId: number, questionId: number) => {
        await answerRepo.DeleteAnswerAsync(answerId);
        let updateQuiz = UpdateQuizWithDeleteAnswer(quiz, selectedRound.id, questionId, answerId);
        onQuizChange(updateQuiz);
    }

    const handleAddUrlAttachment = async (url: string, source: AttachmentSource, questionId: number) => {
        const result = await questionRepo.AddAttachmentAsync(questionId, {
            Source: source,
            Url: url
        } as AddAttachment);

        if (result instanceof Error) {
        }
        else {
            let updatedQuiz = UpdateQuizWithAttachmentAdd(quiz, selectedRound.id, questionId, result)
            onQuizChange(updatedQuiz);
        }
    }

    const handleDeleteAttachment = async (attachmentId: number, questionId: number) => {
        await questionRepo.DeleteAttachmentAsync(questionId, attachmentId);
        let updatedQuiz = UpdateQuizWithDeletedAttachment(quiz, selectedRound.id, questionId, attachmentId);
        onQuizChange(updatedQuiz);
    }

    const handleFileUploadAttachment = async (file: File, questionId: number): Promise<FileUploadStatus> => {
        const result = await questionRepo.UploadFileAsync(questionId, file);
        if (result instanceof Error) {
            return FileUploadStatus.Fail;
        }
        else {
            let updatedQuiz = UpdateQuizWithAttachmentAdd(quiz, selectedRound.id, questionId, result)
            onQuizChange(updatedQuiz);
            return FileUploadStatus.Succeeded;
        }
    };

    return (
        <>
            <div id="question-list">
                <DndContext
                    sensors={sensors}
                    collisionDetection={closestCenter}
                    onDragEnd={handleDragEnd}
                    measuring={measuringConfig}
                    modifiers={[restrictToVerticalAxis]}
                >
                    <SortableContext
                        items={quiz.rounds.find(x => x.id == selectedRound.id).questionsFromRound}
                        strategy={verticalListSortingStrategy}
                    >
                        {quiz.rounds.find(x => x.id == selectedRound.id)
                            .questionsFromRound
                            .sort((a: QuestionFromRound, b: QuestionFromRound) => a.sequence - b.sequence)
                            .map((q) => (
                                <div
                                    key={q.id}
                                    data-question-id={q.id}
                                    ref={(el) => {
                                        if (el) questionRefs.current.set(q.id, el);
                                    }}>
                                    <SortableQuestionDesktop
                                        key={`${q.id}`}
                                        initialQuestion={q}
                                        onDeleteQuestion={handleOnDeleteQuestion}
                                        quiz={quiz}
                                        selectedRound={selectedRound}
                                        onQuestionChange={onQuestionChange}
                                        descriptionRef={descriptionRef}
                                        onAddAnswer={handleAddAnswer}
                                        onDeleteAnswer={handleDeleteAnswer}
                                        onUpdateAnswer={handleUpdateAnswer}
                                        onAddUrlAttachment={handleAddUrlAttachment}
                                        onDeleteAttachment={handleDeleteAttachment}
                                        onFileAttachmentUpload={handleFileUploadAttachment}
                                    />

                                </div>
                            ))}
                    </SortableContext>
                </DndContext>
            </div>

            <div>
                <Button
                    variant="outlined"
                    startIcon={<AddIcon className='AddQuestionIconButton' />}
                    fullWidth
                    className="question-list-element"
                    onClick={handleAddQuestionClick}
                >
                </Button>
            </div>
        </>
    );
}

export default QuestionListDesktop;
