import {  Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,  Tooltip,  Typography } from "@mui/material";
import { ScoresFullScreenProps } from "../../Props/Scores/ScoresFullScreenProps";
import { UNASSIGNED_TEAM_ID } from "../../../Shared/Constants/UnassignedTeamId";
import { GetMaxRoundPoint, GetPoint, GetTotalPointsOfQuizTeam } from "../../Shared/Scores/QuizTeamRoundHelper";
import { TeamRoundPoint } from "../../../../interfaces/Quiz/TeamRoundPoint";
import "../../Style/Scores/scoresFullScreen.scss";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import { ScoreInputField } from "./ScoreInputField";
import { useState } from "react";
import LeaderboardOptionsDialog from "../LeaderBoardOptionsDialog";
import { calculateLeaderboard, calculateTotalPointsOfQuiz } from "./ScoresHelper";
const ScoresFullScreenComponent: React.FC<ScoresFullScreenProps> = ({ quiz, changeTeamRoundPointState, saveTeamRoundPoint, totalQuizPoints }) => {

  const [isLeaderboardModalOpen, setIsLeaderboardModalOpen] = useState<boolean>(false);
  const handleLeaderboardClick = () => {
    setIsLeaderboardModalOpen(true); 
  };

  const handleCloseLeaderboardModal = () => {
    setIsLeaderboardModalOpen(false);
  };

    return (
        <>
            <TableContainer className="scoresTableContainer" component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow className="headerRow">
                            <TableCell className="teamNameRow">
                            <Tooltip title="Leaderboard">
                                <Button variant="contained" onClick={handleLeaderboardClick} >
                                    <EmojiEventsIcon />
                                </Button>
                            </Tooltip>
                            </TableCell>
                            {quiz.rounds.map((round) => (
                                <TableCell key={round.id} className="RoundNameHeader">
                                    <Typography className="headerTypography">
                                        {round.name}
                                    </Typography>
                                </TableCell>
                            ))}
                            <TableCell className="totalScoreRow">
                                <Typography className="headerTypography">
                                    Totaal
                                </Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {quiz.quizTeams
                            .filter((quizTeam) => quizTeam && quizTeam.id && quizTeam.id !== UNASSIGNED_TEAM_ID)
                            .map((quizTeam, index) => {
                                const backgroundColorClass = index % 2 === 0 ? 'evenRow' : 'unevenRow';

                                return (
                                    <TableRow key={quizTeam.id} className={backgroundColorClass}>
                                        <TableCell className={`teamNameCell ${backgroundColorClass}`}>
                                            <Typography className="teamNameTypography">
                                                {quizTeam.name}
                                            </Typography>
                                        </TableCell>

                                        {quiz.rounds.map((round) => (
                                            <TableCell key={`${quizTeam.id}-${round.id}`} className="scoreTable">
                                                    <ScoreInputField
                                                        maxRoundPoint={GetMaxRoundPoint(quiz.rounds, round.id)}
                                                        teamRoundPoint={{quizTeamId: quizTeam.id, roundId: round.id, points: GetPoint(quiz.teamRoundPoints, quizTeam.id, round.id)} as TeamRoundPoint}
                                                        changeTeamRoundPointState={changeTeamRoundPointState}
                                                        saveTeamRoundPoint={saveTeamRoundPoint}
                                                    />

                                            </TableCell>
                                        ))}

                                        <TableCell
                                            className={`totalScoreRow ${backgroundColorClass}`}>
                                            <Typography className="totalScoreTypography">
                                                <span className="totalPointsOfQuizTeam">{GetTotalPointsOfQuizTeam(quiz.teamRoundPoints, quizTeam.id)}</span>
                                                <span className="totalQuizPoints">/{totalQuizPoints}</span>
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>

            <LeaderboardOptionsDialog open={isLeaderboardModalOpen} onClose={handleCloseLeaderboardModal}
                leaderBoard={calculateLeaderboard(quiz)} totalPointsQuiz={calculateTotalPointsOfQuiz(quiz)}/>
        </>
    )
}

export default ScoresFullScreenComponent