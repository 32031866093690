import { useEffect, useState } from "react";
import AddQuizPlayerComponent from "../../QuizPlayer/Components/AddQuizPlayerComponent";
import { QuizTeamManagerProps } from "../Props/QuizTeamManagerProps";
import DeleteIcon from "@mui/icons-material/Delete";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import "../Styles/QuizTeamManagerMobile.scss"
import { Box, Button, IconButton, MenuItem, Select, TextField, Typography } from "@mui/material";
import { QuizPlayer } from "../../../interfaces/QuizPlayer/QuizPlayer";
import { UNASSIGNED_TEAM_ID } from "../../Shared/Constants/UnassignedTeamId";
import { QuizTeamWithQuizPlayers } from "../../../interfaces/QuizTeam/QuizTeamWithQuizPlayers";
import { QuizPlayerCard } from "../../QuizPlayer/Components/QuizPlayerCard";

const QuizTeamManagerMobile: React.FC<QuizTeamManagerProps> = ({ quizTeams, onQuizPlayerDeleteInAssignedTeam, onQuizPlayerDeleteInUnassignedTeam, moveQuizPlayerToTeam, onDeleteQuizTeam, onAddGuestQuizTeam, onAddClickQuizPlayerGuest, onAddClickQuizPlayerUserMultiSelect, onAddClickQuizPlayerWithEmail, quizPlayerUsersNotAttachedToQuiz, onSendInvitationMailQuizPlayers }) => {
    const [selectedQuizTeam, setSelectedQuizTeam] = useState<QuizTeamWithQuizPlayers | null>(null);
    const [guestQuizTeamName, setGuestQuizTeamName] = useState<string>("");

    useEffect(() => {
        const assignedQuizTeams = quizTeams.filter((quizTeam) => quizTeam.id !== UNASSIGNED_TEAM_ID);
    
        if (assignedQuizTeams.length > 0) {
            setSelectedQuizTeam((prevSelectedTeam) =>
                prevSelectedTeam && assignedQuizTeams.some((team) => team.id === prevSelectedTeam.id)
                    ? prevSelectedTeam
                    : assignedQuizTeams[0]
            );
        }
    }, [quizTeams]);

    const unassignedQuizPlayers = quizTeams
        .filter((team) => team.id === UNASSIGNED_TEAM_ID)
        .flatMap((team) => team.quizPlayers);

    const handleSelectQuizTeam = (quizteamId: number) => {
        const quizteam = quizTeams.filter(quizTeam => quizTeam.id == quizteamId)[0] as QuizTeamWithQuizPlayers;
        setSelectedQuizTeam(quizteam);
    };

    const handleDeleteQuizTeam = (quizteam: QuizTeamWithQuizPlayers) => {
        onDeleteQuizTeam(quizteam);
    };

    const handleUnassignedQuizPlayerItemClick = (quizPlayerId: number) => {
        if (selectedQuizTeam == null) return;
        moveQuizPlayerToTeam(quizPlayerId, UNASSIGNED_TEAM_ID, selectedQuizTeam.id);
    
        const playerToMove = unassignedQuizPlayers.find((player) => player.id === quizPlayerId);
    
        if (playerToMove) {
            const updatedSelectedTeam = {
                ...selectedQuizTeam,
                quizPlayers: [...selectedQuizTeam.quizPlayers, playerToMove]
            };

            setSelectedQuizTeam(updatedSelectedTeam);
        }
    };
    

    const handleUnassingedQuizPlayerDelete = (quizPlayer: QuizPlayer) => {
        onQuizPlayerDeleteInUnassignedTeam(quizPlayer.id, UNASSIGNED_TEAM_ID);

        const updatedSelectedTeam = {
            ...selectedQuizTeam,
            quizPlayers: selectedQuizTeam.quizPlayers.filter((player) => player.id !== quizPlayer.id)
        };
    
        setSelectedQuizTeam(updatedSelectedTeam); 
    }

    const handleAassingedQuizPlayerDelete = (quizPlayer: QuizPlayer) => {
        onQuizPlayerDeleteInAssignedTeam(quizPlayer.id, selectedQuizTeam.id);

        const updatedSelectedTeam = {
            ...selectedQuizTeam,
            quizPlayers: selectedQuizTeam.quizPlayers.filter((player) => player.id !== quizPlayer.id)
        };
    
        setSelectedQuizTeam(updatedSelectedTeam); 
    }

    const handleAddQuizTeamClick = () => {
        if(guestQuizTeamName.length <= 0) return;

        onAddGuestQuizTeam(guestQuizTeamName);
        setGuestQuizTeamName("");
    }

    const handleSendQuizPlayerInvitationEmail = (quizPlayer: QuizPlayer) => {
        onSendInvitationMailQuizPlayers([quizPlayer])
      }
    
      const handleSendQuizPlayersInvitationEmail = (quizPlayers: QuizPlayer[]) => {
        onSendInvitationMailQuizPlayers(quizPlayers);
      }

    return (<>
        <div id="quiz-team-manager-container-mobile">
            <AddQuizPlayerComponent quizPlayerUsersNotAttachedToQuiz={quizPlayerUsersNotAttachedToQuiz}
                onAddClickQuizPlayerGuest={onAddClickQuizPlayerGuest}
                onAddClickQuizPlayerUserMultiSelect={onAddClickQuizPlayerUserMultiSelect}
                onAddClickQuizPlayerWithEmail={onAddClickQuizPlayerWithEmail} />

            <div id="unassigned-quizteam-container" className="quizteam-container">
                <Select
                    displayEmpty
                    fullWidth
                    value=""
                    renderValue={() => "Niet toegewezen spelers"}
                >
                    {unassignedQuizPlayers.map((quizPlayer) => {
                        return (
                            <MenuItem key={quizPlayer.id} value={quizPlayer.id} onClick={() => handleUnassignedQuizPlayerItemClick(quizPlayer.id)}>
                                <QuizPlayerCard
                                    key={quizPlayer.id}
                                    quizPlayer={quizPlayer}
                                    onPlayerDelete={handleUnassingedQuizPlayerDelete}
                                    isDraggable={false} 
                                    onSendInvitationEmailQuizPlayer={handleSendQuizPlayerInvitationEmail}/>
                            </MenuItem>
                        );
                    })}
                </Select>
            </div>

            <hr />

            <div id="add-quiz-team-container">
                <div id="add-guest-quiz-team">
                    <TextField
                        label="Toevoegen gast team"
                        variant="outlined"
                        value={guestQuizTeamName}
                        fullWidth
                        onChange={(e) => setGuestQuizTeamName(e.target.value)}
                    />
                    <Button variant="contained" onClick={handleAddQuizTeamClick}>
                        <GroupAddIcon />
                    </Button>
                </div>
            </div>

            <div id="assinged-quizteams-container">
                <Select
                    value={selectedQuizTeam || null}
                    onChange={(e) => handleSelectQuizTeam(Number(e.target.value))}
                    displayEmpty
                    fullWidth
                    renderValue={(selected) => {
                        if (!selected) {
                            return "Maak een team";
                        }

                        return (
                            <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
                                <Typography>{selected.name}</Typography>
                            </Box>
                        );
                    }}
                >
                    {quizTeams.filter(quizTeam => quizTeam.id != UNASSIGNED_TEAM_ID).map((quizTeam) => (
                        <MenuItem key={quizTeam.id} value={quizTeam.id}>
                            <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
                                <Typography>{quizTeam.name}</Typography>
                                <IconButton onClick={() => handleDeleteQuizTeam(quizTeam)}>
                                    <DeleteIcon />
                                </IconButton>
                            </Box>
                        </MenuItem>
                    ))}
                </Select>
            </div>


            <div id="quiz-players-container">
                {selectedQuizTeam &&
                    selectedQuizTeam.quizPlayers.map((quizPlayer) => (
                        <QuizPlayerCard
                            key={quizPlayer.id}
                            quizPlayer={quizPlayer}
                            onPlayerDelete={handleAassingedQuizPlayerDelete}
                            isDraggable={false}
                            onSendInvitationEmailQuizPlayer={handleSendQuizPlayerInvitationEmail}
                        />
                    ))}
            </div>
        </div>
    </>)
}
export default QuizTeamManagerMobile;